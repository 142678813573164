<template>
  <section class="filters-footer">
    <a class="filter-close" href="" @click.prevent="applyFilters">{{
      $t("close")
    }}</a>
    <div>
      <a class="filter-reset" href="" @click.prevent="removeFilters">{{
        $t("reset_filters")
      }}</a>
      <button @click.prevent="applyFilters">{{ $t("search") }}</button>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "SearchFiltersFooter",
  computed: mapState(["filterParameters"]),
  methods: {
    ...mapActions([
      "updateFilters",
      "resetFilters",
      "getListings",
      "getMapListings",
      "closeFilters"
    ]),
    removeFilters() {
      this.resetFilters();
      this.getListings();
      this.getMapListings();
      this.$emit("isMinOverMax", false);
    },
    applyFilters() {
      if (
        Number(this.filterParameters.min_surface) >
        Number(this.filterParameters.max_surface)
      ) {
        return this.$emit("isMinOverMax", true);
      }
      this.closeFilters();
      this.updateFilters({ name: "offset", value: 0 });
      this.getListings();
      this.getMapListings();
      this.$emit("isMinOverMax", false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.filters-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  align-items: center;
  font-size: 14px;
  z-index: 999;

  @media #{$tablet-landscape-and-up} {
    width: 66.66667%;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.18);
    display: flex;
    justify-content: space-between;
  }

  button {
    width: 100%;
    background-color: #ed9473;
    color: white;
    font-weight: 600;
    text-align: center;
    padding: $space-2x $space-5x;
    border: none;
    font-size: 18px;
    @media #{$tablet-landscape-and-up} {
      margin: $space-1x $space-4x $space-1x 0;
      width: auto;
      border-radius: 4px;
      font-size: 14px;
    }
  }
  a {
    text-decoration: none;
    font-weight: 500;
  }
}

.filter-close,
.filter-reset {
  display: none;
  @media #{$tablet-landscape-and-up} {
    display: inline-block;
  }
}

.filter-close {
  @media #{$tablet-landscape-and-up} {
    color: $black;
    margin-left: $space-4x;
  }
}

.filter-reset {
  @media #{$tablet-landscape-and-up} {
    color: $pink;
    margin-right: $space-4x;
  }
}
</style>
