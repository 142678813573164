<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 93 93"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter
        x="-32.0%"
        y="-32.0%"
        width="164.0%"
        height="164.0%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feOffset
          dx="0"
          dy="4"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          stdDeviation="4"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        ></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.429769449 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
    </defs>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Annonce" transform="translate(-674.000000, -170.000000)">
        <g id="Photo" transform="translate(0.000000, 32.000000)">
          <g id="ic_photo" transform="translate(683.000000, 143.000000)">
            <g id="ic_photo-copy" filter="url(#filter-1)">
              <circle
                id="Oval"
                stroke="#FFFFFF"
                stroke-width="2"
                fill-opacity="0.150786713"
                fill="#000000"
                cx="37.5"
                cy="37.5"
                r="37.5"
              ></circle>
              <polygon
                id="Triangle"
                fill="#FFFFFF"
                transform="translate(41.007812, 36.500000) rotate(-270.000000) translate(-41.007812, -36.500000) "
                points="41.0078125 27.5 55.0078125 45.5 27.0078125 45.5"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "Play"
};
</script>
