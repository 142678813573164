<template>
  <div class="dropdown" v-click-outside="closeDropdown">
    <div class="dropdown-active-element" @click="toggleDropdown">
      <p class="dropdown-name sup-text">{{ dropdownName }}</p>
      <p class="dropdown-selected-value" v-if="value">{{ value.name }}</p>
      <p class="dropdown-selected-value" v-else></p>
      <span
        :class="[
          'dropdown-chevron',
          { 'dropdown-chevron-up': isDropdownOpened }
        ]"
      />
    </div>
    <div v-show="isDropdownOpened" class="dropdown-options">
      <div
        class="dropdown-option"
        v-for="(option, index) in options"
        :key="`${option.name}-${index}`"
        @click="selectOption(option)"
      >
        <div
          v-if="typeof value === 'undefined' || option.value !== value.value"
        >
          <p class="dropdown-name sup-text" v-if="displayNameOnOptions">
            {{ dropdownName }}
          </p>
          <p class="dropdown-option-name">{{ option.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: "Dropdown",
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    options: Array,
    value: Object,
    dropdownName: {
      type: String,
      default: ""
    },
    displayNameOnOptions: {
      type: Boolean,
      value: false
    }
  },
  data() {
    return {
      isDropdownOpened: false
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpened = !this.isDropdownOpened;
    },
    closeDropdown() {
      this.isDropdownOpened = false;
    },
    selectOption(option) {
      this.isDropdownOpened = false;
      this.$emit("change", option.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  min-width: 160px;
}

.dropdown-active-element {
  margin: $space-1x $space-2x $space-1x $space-3x;
}
.dropdown-options {
  position: absolute;
  background-color: white;
  border: 1px solid $light-grey;
  width: 100%;
  z-index: 99;
}
.dropdown-option {
  margin: $space-1x $space-2x;
}

.dropdown-selected-value,
.dropdown-option-name {
  margin: 0;
  font-weight: 600;
}

.dropdown-selected-value {
  display: inline-block;
  margin-right: $space-3x;
}

.dropdown-chevron {
  display: inline-block;
  top: 50%;
  position: absolute;
  right: $space-2x;
  transition: transform 300ms;
  margin-top: 2px;

  &::after {
    content: "";
    background-image: url("../assets/images/arrow.svg");
    background-repeat: no-repeat;
    width: 9px;
    height: 6px;
    display: block;
  }
}

.dropdown-chevron-up {
  transform: rotate(180deg);
}
</style>
