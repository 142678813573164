<template>
  <div class="number-of-results-container">
    <p class="sup-text number-of-result-text-xs">{{ $t("goods") }}</p>
    <p class="number-of-results">
      <strong>{{ numberOfResults }} </strong>
      <span class="number-of-result-text-lg">{{
        $tc("listing_found", numberOfResults)
      }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "NumberOfResults",
  props: {
    numberOfResults: Number
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.number-of-results {
  margin: 0;
}

.number-of-result-text-lg {
  display: none;
  @media #{$tablet-landscape-and-up} {
    display: inline-block;
    font-size: 12px;
    color: $dark-grey;
  }
}

.number-of-result-text-xs {
  display: block;
  @media #{$tablet-landscape-and-up} {
    display: none;
  }
}
</style>
