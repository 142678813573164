<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Search"
        transform="translate(-201.000000, -24.000000)"
        :fill="color"
        fill-rule="nonzero"
      >
        <g id="Group-2">
          <g id="Search" transform="translate(188.000000, 13.000000)">
            <g
              id="noun_Search_2276935"
              transform="translate(13.000000, 11.000000)"
            >
              <path
                d="M10.2309431,0 C5.94822272,0 2.46435176,3.48363238 2.46435176,7.76605944 C2.46435176,9.54855499 3.06841997,11.1905218 4.08177522,12.502123 L0.292171769,16.2914669 C-0.0973905897,16.6810026 -0.0973905897,17.3146144 0.292171769,17.7066155 C0.486952948,17.9013834 0.743373742,18 0.999794535,18 C1.25621533,18 1.51263612,17.9013834 1.7074173,17.7066155 L5.49702075,13.9172716 C6.80871173,14.9305575 8.45079104,15.5345843 10.2334087,15.5345843 C14.516129,15.5345843 18,12.0509519 18,7.76852486 C18,3.48609779 14.5136634,0 10.2309431,0 Z M10.2309431,13.5326668 C7.05033902,13.5326668 4.46394083,10.9464457 4.46394083,7.76605944 C4.46394083,4.5856732 7.05033902,1.99945213 10.2309431,1.99945213 C13.4115472,1.99945213 15.9979453,4.5856732 15.9979453,7.76605944 C15.9979453,10.9464457 13.4115472,13.5326668 10.2309431,13.5326668 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "Search"
};
</script>
