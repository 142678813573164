<template>
  <PropertyDetailsLayout :title="$t('visit_request')">
    <div v-if="messageSent" class="visit-request-success">
      <div class="visit-request-succes-icon">
        <Check :height="25" />
      </div>
      <h2>
        {{ $t("request_sent_success") }}
      </h2>
      <p>{{ $t("we_will_contact_you") }}</p>
      <p>{{ $t("create_alert_hint") }}</p>
      <div class="button-container">
        <a :href="CREATE_ALERT_PATH" class="button-primary" target="_blank">{{
          $t("create_alert")
        }}</a>
      </div>
      <div class="button-container">
        <router-link :to="{ name: 'search' }" class="back-to-listing-link">
          {{ $t("continue_search") }}
        </router-link>
      </div>
    </div>
    <form
      v-else
      @submit.prevent="validateBeforeSubmit"
      class="visit-request-form"
    >
      <h2 class="hidden-small">
        {{ $t("visit_request") }}
      </h2>
      <div class="input-group input-group-small">
        <label for="first_name">{{ $t("first_name") }}*</label>
        <input
          type="text"
          :data-vv-as="$t('first_name').toLowerCase()"
          id="first_name"
          name="first_name"
          v-validate="'required'"
          v-model="first_name"
        />
        <span v-show="errors.has('first_name')" class="error">
          {{ errors.first("first_name") }}
        </span>
      </div>
      <div class="input-group input-group-small">
        <label for="last_name">{{ $t("last_name") }}*</label>
        <input
          :data-vv-as="$t('last_name').toLowerCase()"
          type="text"
          id="last_name"
          name="last_name"
          v-validate="'required'"
          v-model="last_name"
        />
        <span v-show="errors.has('last_name')" class="error">
          {{ errors.first("last_name") }}
        </span>
      </div>
      <div class="input-group">
        <label for="email">{{ $t("email") }}*</label>
        <input
          :data-vv-as="$t('email').toLowerCase()"
          type="email"
          id="email"
          name="email"
          v-validate="'required|email'"
          v-model="email"
          data-vv-validate-on="blur"
        />
        <span v-show="errors.has('email')" class="error">
          {{ errors.first("email") }}
        </span>
      </div>
      <div class="input-group">
        <label for="phone">{{ $t("phone") }}*</label>
        <input
          :data-vv-as="$t('phone').toLowerCase()"
          type="text"
          id="phone"
          name="phone"
          v-validate="{
            required: true,
            regex: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
          }"
          v-model="phone"
          data-vv-validate-on="input"
        />
        <span v-show="errors.has('phone')" class="error">
          {{ errors.first("phone") }}
        </span>
      </div>

      <div class="input-group">
        <label for="message">{{ $t("message") }}*</label>
        <textarea
          :data-vv-as="$t('message').toLowerCase()"
          name="message"
          id="message"
          v-validate="'required'"
          v-model="message"
          rows="6"
        />
        <span v-show="errors.has('message')" class="error">
          {{ errors.first("message") }}
        </span>
      </div>
      <div class="buttons">
        <button @click.prevent="$router.go(-1)" class="button cancel">
          {{ $t("cancel") }}
        </button>
        <button @click.prevent="validateBeforeSubmit" class="button primary">
          {{ $t("send") }}
        </button>
      </div>
    </form>
  </PropertyDetailsLayout>
</template>

<script>
import PropertyDetailsLayout from "@/components/Layouts/PropertyDetailsLayout.vue";
import { Check } from "@/components/Icons";
import { CREATE_ALERT_PATH } from "@/constants";

import { mapState, mapActions } from "vuex";

export default {
  name: "VisitRequest",
  inject: ["$validator"],
  components: {
    Check,
    PropertyDetailsLayout
  },
  data() {
    return {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      message: null,
      CREATE_ALERT_PATH
    };
  },
  computed: {
    ...mapState(["messageSentSucces", "messageSentFail"]),
    slug() {
      return this.$route.params.slug;
    },
    messageSent() {
      return this.messageSentSucces.indexOf(this.slug) !== -1;
    },
    messageError() {
      return this.messageSentFail.find(error => error.slug === this.slug);
    }
  },
  methods: {
    ...mapActions(["submitVisitRequest"]),
    validateBeforeSubmit() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          const actionParams = {
            slug: this.slug,
            params: JSON.parse(
              JSON.stringify({
                first_name: this.$data.first_name,
                last_name: this.$data.last_name,
                email: this.$data.email,
                phone: this.$data.phone,
                message: this.$data.message
              })
            )
          };
          this.submitVisitRequest(actionParams);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.hidden-small {
  display: none;
  @media #{$tablet-landscape-and-up} {
    display: block;
  }
}
.hidden-large {
  display: block;
  @media #{$tablet-landscape-and-up} {
    display: none;
  }
}

.input-group {
  width: 100%;
  position: relative;
  padding-bottom: $space-3x;

  label {
    display: block;
  }

  input,
  textarea {
    border: 1px solid $light-grey !important;
    width: calc(100% - 2px); // Border width
    padding: $space-2x;
    box-sizing: border-box !important;
  }

  .invalid {
    border-color: $pink;
  }

  &.input-group-small {
    @media #{$tablet-portrait-and-up} {
      width: calc(50% - #{$space-1x} - 2.1px);
      display: inline-block;
    }

    &:first-of-type {
      @media #{$tablet-portrait-and-up} {
        margin-right: $space-2x;
      }
    }
    &:last-of-type {
      @media #{$tablet-portrait-and-up} {
        margin-left: $space-2x;
      }
    }
  }
}

.error {
  position: absolute;
  left: 0;
  top: calc(100% - #{$space-3x});
  color: $pink;
  font-size: 13px;
}

.visit-request-success {
  p {
    text-align: center;
    max-width: 290px;
    padding: 0 $space-4x;
    margin: $space-3x auto;
  }

  .primary {
    padding: $space-1x;
    line-height: 22px;
    @media #{$tablet-portrait-and-up} {
      padding: $space-1x $space-3x;
    }
  }

  a {
    display: inline-block;
  }
}

.button-container {
  text-align: center;
  margin: $space-3x auto;

  &:last-of-type {
    @media #{$tablet-landscape-and-up} {
      margin-bottom: $space-12x;
    }
  }
}

.visit-request-succes-icon {
  margin-top: $space-3x;
  @media #{$tablet-landscape-and-up} {
    margin-top: $space-12x;
  }
  svg {
    border-radius: 50%;
    border: 4px solid $black;
    line-height: 0;
    padding: 5px;
    margin: 0 auto;
    display: block;
  }
}

.button {
  border: 1px solid $black;
  border-radius: 30px;
  color: $black;
  font-size: 15px;
  font-weight: 600;
  line-height: 30px;
  text-transform: uppercase;
  background-color: transparent;
  padding: $space-1x $space-3x;
  @media #{$tablet-portrait-and-up} {
    font-size: 18px;
  }
  @media #{$tablet-landscape-and-up} {
    margin-left: $space-2x;
    padding: $space-1x $space-4x;
  }
}

.primary {
  color: white;
  background-color: $pink;
  border-color: $pink;
  text-decoration: none;
  padding: $space-1x $space-2x;
  @media #{$tablet-landscape-and-up} {
    padding: $space-1x $space-3x;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  @media #{$tablet-landscape-and-up} {
    justify-content: flex-end;
  }
}

.visit-request {
  &-disclaimer {
    font-weight: bold;
    text-align: center;
    font-size: larger;
  }

  &-title {
    color: $pink;
  }
  &-subtitle {
    text-align: center;
    font-size: larger;
  }
}
</style>
