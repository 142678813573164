<template>
  <!-- TODO : use placeholder for a better loading experience -->
  <div v-if="displayedListing">
    <PropertyHeader
      :main-image="mainImage"
      :is-sold="displayedListing.is_sold"
      :is-unavailable="displayedListing.is_unavailable"
    />
    <GalleryModal />
    <section class="property-content">
      <section>
        <PropertySummary />
        <Collapsible :collapsedHeight="240" class="property-description">
          <p class="description-text" v-if="displayedListing.description">
            {{displayedListing.description}}
          </p>
        </Collapsible>
        <template v-if="displayedListing.exteriors">
          <h3>{{ $t("exteriors") }}</h3>
          <ListWithIcons
            :elements="displayedListing.exteriors"
            class="property-outdoor"
          />
        </template>
        <template v-if="addressLatLng">
          <h3 id="map">{{ $t("location") }}</h3>
          <h3>
            <Pin color="#251540" height="18" />
            {{ displayedListing.address }}
          </h3>
          <GmapMap
            :center="addressLatLng"
            :zoom="14"
            class="property-map"
            :options="{
              fullscreenControl: false,
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false
            }"
          >
            <GmapMarker
              v-if="!displayedListing.is_address_anonymous"
              :position="addressLatLng"
              :icon="{ url: require('../assets/images/marker.svg') }"
            />
          </GmapMap>
        </template>
        

        <PropertyServices
          :listingServices="displayedListing.property_services"
          :buildingServices="displayedListing.building_services"
          :heatingServices="heating"
        />
        <div v-if="diagnosticLabels.dpe" class="property-diagnostics">
          <h3>{{ $t("diagnostics") }}</h3>
          <div class="property-diagnostic property-diagnostic-dpe">
            <p>{{ $t("dpe") }}</p>
            <div class="property-diagnostic-common">
              <p class="property-diagnostic-value">
                {{ displayedListing.dpe }}
                <span class="property-diagnostic-value-unit">
                  {{ $t("dpe_unit") }}
                </span>
              </p>
              <template v-if="!isOldDpe">
                <span class="property-diagnostic-common-separator"> | </span>
                <p class="property-diagnostic-value">
                  {{ displayedListing.ges }}
                  <span class="property-diagnostic-value-unit">
                    {{ $t("ges_unit") }}
                  </span>
                </p>
              </template>
            </div>
            <DiagnosticScale
              :value="diagnosticLabels.dpe"
              :valueRange="diagnosticColors.dpe"
            />
          </div>
          <div
            class="property-diagnostic property-diagnostic-ges"
            v-if="diagnosticLabels.ges"
          >
            <p>{{ $t(isOldDpe ? "old_ges" : "ges") }}</p>
            <p class="property-diagnostic-value">
              {{ displayedListing.ges }}
              <span class="property-diagnostic-value-unit">
                {{ $t("ges_unit") }}
              </span>
            </p>
            <DiagnosticScale
              :value="diagnosticLabels.ges"
              :valueRange="diagnosticColors.ges"
            />
          </div>
          <p v-if="isOldDpe" class="property-diagnostic-mention">
            {{ $t("diagnostic_before_july_2021") }}
          </p>
          <p
            v-if="dpeEstimatedAmountMention"
            class="property-diagnostic-mention"
          >
            {{ dpeEstimatedAmountMention }}
          </p>
          <p
            v-if="dpeExcessiveEnergyMention"
            class="property-diagnostic-mention"
          >
            {{ dpeExcessiveEnergyMention }}
          </p>
        </div>
      </section>
      <aside>
        <div>
          <PropertyPriceDetails
            class="show-flex-large"
            :is-sold="displayedListing.is_sold"
            :price="displayedListing.price"
            :surface="displayedListing.surface"
            :display-is-sold="false"
          />
          <div class="property-disclaimer">
            {{ displayedListing.price_disclaimer }}
          </div>
          <div class="property-ctas" v-if="!displayedListing.is_sold">
            <router-link
              :to="{
                name: 'visit-request',
                params: { slug: displayedListing.slug }
              }"
            >
              {{ $t("visit_property") }}
            </router-link>
          </div>
          <AgentCard :agent="displayedListing.agent" />

          <a class="property-alert" :href="CREATE_ALERT_PATH" target="_blank">
            <Alert class="property-alert__icon" />
            <span class="property-alert__text">{{ $t("property_alert") }}</span>
            <Arrow
              class="property-alert__chevron"
              direction="right"
              width="30"
              height="30"
              color="#251540"
            />
          </a>

          <div class="guide-block">
            <h2 class="guide-block__title">{{ $t("guide-block.title") }}</h2>
            <ul class="guide-block__list">
              <li
                class="guide-block__item"
                v-for="link in $t('guide-block.list')"
                :key="link.name"
              >
                <a class="guide-block__link" :href="link.path" target="_blank">
                  {{ link.name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </section>
  </div>
</template>

<script>
import PropertyHeader from "@/components/Property/PropertyHeader.vue";
import PropertySummary from "@/components/Property/PropertySummary.vue";
import PropertyServices from "@/components/Property/PropertyServices.vue";
import PropertyPriceDetails from "@/components/Property/PropertyPriceDetails.vue";
import GalleryModal from "@/components/GalleryModal.vue";
import Collapsible from "@/components/Collapsible.vue";
import AgentCard from "@/components/AgentCard.vue";
import DiagnosticScale from "@/components/DiagnosticScale.vue";
import ListWithIcons from "@/components/ListWithIcons.vue";
import Pin from "@/components/Icons/Pin.vue";
import Alert from "@/components/Icons/Alert.vue";
import Arrow from "@/components/Icons/Arrow.vue";

import {
  CREATE_ALERT_PATH,
  DIAGNOSTIC_RANGES,
  OLD_DIAGNOSTIC_RANGES,
  DIAGNOSTIC_COLORS
} from "@/constants";
import { mapState, mapActions } from "vuex";

export default {
  name: "Property",

  components: {
    PropertyHeader,
    PropertySummary,
    PropertyServices,
    Collapsible,
    DiagnosticScale,
    ListWithIcons,
    AgentCard,
    Pin,
    PropertyPriceDetails,
    GalleryModal,
    Alert,
    Arrow
  },

  computed: {
    ...mapState(["displayedListing"]),

    addressLatLng() {
      if (this.displayedListing.lat && this.displayedListing.lng) {
        return {
        lat: this.displayedListing.lat,
        lng: this.displayedListing.lng
        };
      };

      return false;
    },

    mainImage() {
      if (typeof this.displayedListing !== "undefined") {
        return typeof this.displayedListing.high_quality_media !== "undefined"
          ? this.displayedListing.high_quality_media[0]
          : this.displayedListing.low_quality_media[0];
      }
      return "";
    },

    heating() {
      const heating = {};
      if (this.displayedListing.heating_type) {
        heating.type = this.displayedListing.heating_type;
      }
      if (this.displayedListing.heating_mechanism) {
        heating.mechanism = this.displayedListing.heating_mechanism;
      }
      if (this.displayedListing.heating_mode) {
        heating.mode = this.displayedListing.heating_mode;
      }
      return heating;
    },

    title() {
      return (
        this.displayedListing.seo_title ||
        `${this.displayedListing.type} ${this.$tc(
          "room",
          this.displayedListing.room_count
        )} de ${this.displayedListing.surface} m²`
      );
    },

    isOldDpe() {
      return (
        new Date(this.displayedListing.dpe_realization_date) <
        new Date("2021-07-01")
      );
    },

    diagnosticRanges() {
      return this.isOldDpe ? OLD_DIAGNOSTIC_RANGES : DIAGNOSTIC_RANGES;
    },

    diagnosticLabels() {
      return {
        dpe: this.convertDpeValueToLabel("dpe"),
        ges: this.convertDpeValueToLabel("ges")
      };
    },

    alertPath() {
      const {
        price,
        type,
        bedroom_count,
        room_count,
        surface,
        cellar_count,
        parking_count,
        building_services
      } = this.displayedListing;
      const paramName = "buyer_property_search";
      let params = [];
      if (price > 0) {
        params.push(`${paramName}[max_price]=${price}`);
      }
      if (type) {
        let pType = this.propertyTypes.find(
          propertyType => propertyType[0] === type
        );
        if (pType) {
          params.push(`${paramName}[property_type]=${pType[1]}`);
        }
      }
      if (bedroom_count > 0) {
        params.push(`${paramName}[min_bedrooms_count]=${bedroom_count}`);
      }
      if (surface > 0) {
        params.push(`${paramName}[min_surface]=${surface}`);
      }
      if (room_count > 0) {
        params.push(`${paramName}[min_rooms_count]=${room_count}`);
      }
      if (cellar_count > 0) {
        params.push(`${paramName}[property_preference_cellar]=2`);
      }
      if (parking_count > 0) {
        params.push(`${paramName}[property_preference_parking]=2`);
      }
      if (building_services.length) {
        params.push(
          `${paramName}[property_preference_elevator]=${
            building_services.includes("Ascenseur") ? 2 : 1
          }`
        );
        params.push(
          `${paramName}[property_preference_building_caretaker]=${
            building_services.includes("Gardien") ? 2 : 1
          }`
        );
      }
      return `${CREATE_ALERT_PATH}${
        params.length ? "?" + params.join("&") : ""
      }`;
    },

    dpeEstimatedAmountMention() {
      if (
        (this.isOldDpe &&
          !this.displayedListing.montant_depense_energie_estime) ||
        (!this.isOldDpe &&
          !this.displayedListing.montant_depenses_energies_min &&
          !this.displayedListing.montant_depenses_energies_max)
      ) {
        return null;
      } else {
        const year =
          this.displayedListing.annee_indice_prix_energies ||
          this.$t("dpe_estimated_unknown_year");
        let amount;

        if (this.isOldDpe) {
          amount = this.displayedListing.montant_depense_energie_estime;
        } else {
          if (
            this.displayedListing.montant_depenses_energies_min &&
            this.displayedListing.montant_depenses_energies_max
          ) {
            amount = this.$t("dpe_estimated_amount_range", {
              min: this.displayedListing.montant_depenses_energies_min,
              max: this.displayedListing.montant_depenses_energies_max
            });
          } else {
            amount =
              this.displayedListing.montant_depenses_energies_min ||
              this.displayedListing.montant_depenses_energies_max;
          }
        }

        return this.$t("dpe_estimated_amount", {
          amount,
          year
        });
      }
    },

    dpeExcessiveEnergyMention() {
      if (this.isOldDpe || !["F", "G"].includes(this.diagnosticLabels.dpe)) {
        return null;
      } else {
        return this.$t("dpe_excessive_energy", {
          label: this.diagnosticLabels.dpe
        });
      }
    }
  },

  data() {
    return {
      diagnosticColors: DIAGNOSTIC_COLORS,
      listingDetailsFetch: false,
      propertyTypes: [
        ["Appartement", "property_is_apartment"],
        ["Maison", "property_is_house"],
        ["Loft", "property_is_apartment"],
        ["Souplex", "property_is_apartment"],
        ["Duplex", "property_is_apartment"],
        ["Triplex", "property_is_apartment"]
      ],
      CREATE_ALERT_PATH
    };
  },

  created() {
    const { slug } = this.$route.params;
    this.getListingDetails(slug).then(() => {
      if (this.displayedListing) {
        document.title = this.title;
        this.displayedListing.low_quality_media.forEach(media => {
          var meta = document.createElement("meta");
          meta.setAttribute("property", "og:image");
          meta.content = media;
          document.getElementsByTagName("head")[0].appendChild(meta);
        });
      }
    });
  },

  methods: {
    ...mapActions(["getListingDetails"]),
    convertDpeValueToLabel(type) {
      let label;

      if (
        typeof this.displayedListing[type] !== "number" ||
        (!this.isOldDpe &&
          type === "dpe" &&
          typeof this.displayedListing.ges !== "number")
      ) {
        return null;
      }

      if (this.isOldDpe) {
        [label] = this.diagnosticRanges.filter(
          rangeLabel =>
            rangeLabel[type] && this.displayedListing[type] <= rangeLabel[type]
        );
      } else if (type === "dpe") {
        [label] = this.diagnosticRanges.filter(
          rangeLabel =>
            rangeLabel.dpe &&
            rangeLabel.ges &&
            this.displayedListing.dpe < rangeLabel.dpe &&
            this.displayedListing.ges < rangeLabel.ges
        );
      } else {
        [label] = this.diagnosticRanges.filter(
          rangeLabel =>
            rangeLabel.ges && this.displayedListing.ges < rangeLabel.ges
        );
      }

      return label?.value || "G";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.show-flex-large {
  display: none;
  @media #{$tablet-landscape-and-up} {
    display: flex;
  }
}
.property-content {
  background-color: white;
  padding: $space-2x $space-2x $space-8x $space-2x;
  position: relative;
  z-index: 1;

  @media #{$tablet-portrait-and-up} {
    padding: $space-2x;
    padding: $space-4x $space-6x $space-8x $space-6x;
  }

  @media #{$tablet-landscape-and-up} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media #{$desktop-and-up} {
    max-width: 1200px;
    margin: -$space-14x auto 0;
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: $space-1x;
  }

  > aside {
    @media #{$tablet-landscape-and-up} {
      flex-grow: 1;
      margin-left: $space-3x;
    }
    @media #{$desktop-and-up} {
      flex-basis: 300px;
      margin-left: $space-12x;
    }

    > div {
      @media #{$tablet-landscape-and-up} {
        position: sticky;
        top: 80px;
      }
    }
  }

  > section {
    max-width: 740px;
    flex-basis: 500px;
    flex-grow: 2;
  }
}

.property-description {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: $space-3x;
}
.description-text {
  margin-top: 0;
  white-space: pre-wrap;
}
.property-outdoor {
  margin-bottom: $space-1x;
}

.property-map {
  height: 180px;
  margin-bottom: $space-3x;
  @media #{$tablet-portrait-and-up} {
    height: 225px;
  }
}

.property-neighbourhood {
  font-size: 14px;
  line-height: 20px;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-height: 100px; /* Fallback for non-webkit */
  margin: 0 auto;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: $space-2x;
}

.property-neighbourhood-link {
  color: $pink;
  font-weight: 600;
  margin-bottom: $space-4x;
  display: inline-block;
  font-size: 14px;
}
.property-heating {
  margin-bottom: $space-4x;
}

.property-diagnostics > *:last-child {
  margin-bottom: $space-5x;
}

.property-diagnostic {
  display: inline-block;
  margin-bottom: $space;
  font-size: 14px;

  > p:first-child + .property-diagnostic-value,
  > p:first-child + .property-diagnostic-common {
    margin: $space-1x 0;
  }
}

.property-diagnostic-mention {
  font-size: 14px;
}

.property-diagnostic-dpe {
  margin-right: $space-10x;
}

.property-diagnostic-common {
  display: flex;

  &-separator {
    font-size: 26px;
    font-weight: bold;
    margin: 0 $space-2x;
  }
}

.property-diagnostic-value {
  margin: 0;
  font-size: 26px;
  font-weight: bold;

  &-unit {
    display: block;
    font-size: 12px;
    font-weight: normal;
  }
}

.property-disclaimer {
  margin-bottom: $space-2x;
  font-size: 14px;
  font-style: italic;
}

.property-visit-disclaimer {
  font-weight: bold;
  margin-bottom: $space-2x;
}

.property-ctas {
  text-transform: uppercase;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  @media #{$tablet-landscape-and-up} {
    position: relative;
    display: inline-block;
    bottom: auto;
    left: auto;
    right: auto;
    margin-bottom: $space-4x;
  }

  a {
    background-color: $pink;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    padding: $space-1x $space-4x;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    display: inline-block;
    text-decoration: none;
    box-sizing: border-box;

    @media #{$tablet-landscape-and-up} {
      border-radius: 22px;
    }
  }
}

.property-alert {
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin: $space-2x 0;
  padding: $space-2x $space-3x;
  background-color: $color-alto;
  color: inherit;
  text-decoration: none;
  transition: background-color 300ms ease-out;
  font-weight: bold;
  font-size: 14px;
  max-width: 400px;
  box-sizing: border-box;

  &:hover {
    background-color: darken($color-alto, 8%);
  }
  &:focus {
    background-color: darken($color-alto, 16%);
  }

  &__icon {
    flex: none;
    width: 50px;
    margin-right: $space-2x;
  }

  &__chevron {
    margin-left: $space-2x;
  }
}

.guide-block {
  border: 1px solid $color-alto;
  border-radius: 4px;
  max-width: 400px;

  &__title {
    text-align: center;
    border-bottom: 1px solid $color-alto;
    font-weight: bold;
    font-size: 18px;
    margin: 0;
    padding: 0.83em 0;
  }

  &__list {
    list-style: none;
    padding: 0 $space-3x;
  }

  &__item {
    margin: 0.5em 0;
  }

  &__link {
    color: inherit;
    padding: 0.25em 0;
    transition: color 200ms ease-out;

    &:hover {
      color: $pink;
    }
  }
}
</style>
