import Api from "@/services/api";
import qs from "qs";

export const fetchListings = params =>
  Api().get("/portal/properties", {
    params: { ...params },
    paramsSerializer: parameters =>
      qs.stringify(parameters, { arrayFormat: "repeat" })
  });
export const fetchMapListings = params =>
  Api().get("/portal/properties", {
    params: {
      ...params,
      max_results: 500,
      offset: undefined,
      include_sold_properties: false
    },
    paramsSerializer: parameters =>
      qs.stringify(parameters, { arrayFormat: "repeat" })
  });
export const fetchListing = slug => Api().get(`/portal/properties/${slug}`);
export const fetchLocations = query =>
  Api().get(`/portal/locations?query=${query}`);

export const postVisitRequestMessage = (slug, params) =>
  Api().post(`/portal/properties/${slug}/visit_requests`, params);
