<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 13 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Annonce"
        transform="translate(-1089.000000, -655.000000)"
        :fill="color"
        fill-rule="nonzero"
      >
        <g id="ColonneDroite" transform="translate(985.000000, 425.000000)">
          <g id="ContactAgent" transform="translate(0.000000, 129.000000)">
            <g id="Phone" transform="translate(104.000000, 101.000000)">
              <path
                d="M1.62573119,0 C0.73764479,0 0.000945014175,0.705308732 0.000945014175,1.55555521 L0.000945014175,2.40277724 C0.000491604439,2.41666274 0.000491604439,2.43055841 0.000945014175,2.4444439 L0.000945014175,15.3333344 C-0.000315004725,15.3564668 -0.000315004725,15.3796464 0.000945014175,15.4027788 L0.000945014175,18.4444448 C0.000945014175,19.294689 0.73764479,20 1.62573119,20 L11.3744485,20 C12.2625349,20 12.9992346,19.294689 12.9992346,18.4444448 L12.9992346,15.3819344 C12.9998516,15.3657397 12.9998516,15.349529 12.9992346,15.3333344 L12.9992346,2.4444439 C13.0002551,2.42362203 13.0002551,2.40276579 12.9992346,2.38194392 L12.9992346,1.55555521 C12.9992346,0.705308732 12.2625349,2.52637361e-14 11.3744485,2.52637361e-14 L1.62573119,2.52637361e-14 L1.62573119,0 Z M1.71363627,1.42857143 L11.2863637,1.42857143 C11.4244594,1.42857143 11.5142857,1.49894821 11.5142857,1.60714286 L11.5142857,1.78571429 L1.48571429,1.78571429 L1.48571429,1.60714286 C1.48571429,1.49894821 1.57554062,1.42857143 1.71363627,1.42857143 L1.71363627,1.42857143 Z M1.48571429,3.21428571 L11.5142857,3.21428571 L11.5142857,14.6428571 L1.48571429,14.6428571 L1.48571429,3.21428571 Z M1.48571429,16.0714286 L11.5142857,16.0714286 L11.5142857,18.3630952 C11.5142857,18.4893244 11.4244594,18.5714286 11.2863637,18.5714286 L1.71363627,18.5714286 C1.57554062,18.5714286 1.48571429,18.4893244 1.48571429,18.3630952 L1.48571429,16.0714286 L1.48571429,16.0714286 Z M6.50000012,16.4285714 C5.9871641,16.4285714 5.57142857,16.8283036 5.57142857,17.3214286 C5.57142857,17.8145313 5.9871641,18.2142857 6.50000012,18.2142857 C7.0128359,18.2142857 7.42857143,17.8145313 7.42857143,17.3214286 C7.42857143,16.8283036 7.0128359,16.4285714 6.50000012,16.4285714 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "Phone"
};
</script>
