<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 36 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Annonce"
        transform="translate(-160.000000, -1079.000000)"
        :fill="color"
        fill-rule="nonzero"
      >
        <g id="Header" transform="translate(0.000000, 64.000000)">
          <g id="Visites">
            <g id="Map" transform="translate(15.000000, 975.000000)">
              <path
                d="M181,58.6342039 C181,48.3428845 172.941061,40 163,40 C153.058939,40 145,48.3428845 145,58.6342039 C145,62.4437224 146.106593,65.9845104 148.001612,68.9360945 L147.998388,68.9360945 L148.02526,68.9733718 C148.328914,69.443511 148.652992,69.8969588 148.995342,70.3359409 L163,90 C163,90 177.672161,69.4429546 177.975278,68.9733718 L178.00215,68.9360945 L177.998925,68.9360945 C179.893945,65.9845104 181,62.4437224 181,58.6342039 Z M163.5,65 C159.357952,65 156,61.642048 156,57.5 C156,53.357952 159.357952,50 163.5,50 C167.642048,50 171,53.357952 171,57.5 C171,61.642048 167.642048,65 163.5,65 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "Pin"
};
</script>
