<template>
  <section
    :class="[
      'search-header-filters-container',
      { 'search-header-filters-open': displayInput }
    ]"
  >
    <div v-if="displayInput" class="filters-mobile-header">
      <a class="filter-mobile-close" href="" @click.prevent="applyFilters">
        <Cross :width="20" color="#251540" />
      </a>
      <h2>{{ $t("filter") }}</h2>
      <a class="filter-mobile-reset" href="" @click.prevent="removeFilters">{{
        $t("erase")
      }}</a>
    </div>
    <div class="search-header-filters">
      <Dropdown
        :dropdownName="$t('region')"
        :value="value"
        :options="regionOptions"
        :displayNameOnOptions="true"
        @change="
          selectedValue =>
            filterChanged({ name: 'region', value: selectedValue })
        "
      />
      <FiltersRegionsInput v-if="displayInput" />
      <FiltersButton v-else />
    </div>
    <div
      v-if="displayInput && (value || filterParameters.zip_codes.length > 0)"
      class="search-header-filters-zip-codes"
    >
      <button
        class="search-header-filters-zip-code"
        v-if="value"
        @click.prevent
      >
        {{ value.name }}
      </button>
      <button
        class="search-header-filters-zip-code has-cross"
        v-for="zip_code in filterParameters.zip_codes"
        :key="zip_code"
        @click.prevent="removeZipCode(zip_code)"
      >
        {{ zip_code }}
        <span>
          <Cross :height="12" />
        </span>
      </button>
    </div>
  </section>
</template>

<script>
import FiltersButton from "@/components/FiltersButton.vue";
import FiltersRegionsInput from "@/components/FiltersRegionsInput.vue";
import Dropdown from "@/components/Dropdown.vue";

import { Cross } from "@/components/Icons";

import { mapActions, mapState } from "vuex";

export default {
  name: "SearchHeaderRegions",
  components: {
    FiltersButton,
    FiltersRegionsInput,
    Dropdown,
    Cross
  },
  props: {
    displayInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      regionOptions: [
        { value: 1, name: "Paris & Île-de-France" },
        { value: 2, name: "Provence-Alpes-Côte d'Azur" },
        { value: 3, name: "Auvergne-Rhône-Alpes" },
        { value: 5, name: "Occitanie" },
        { value: 6, name: "Hauts-de-France" },
        { value: 8, name: "Grand-Est" },
        { value: 9, name: "Normandie" },
        { value: 12, name: "Bretagne" },
        { value: 13, name: "Centre-Val de Loire" },
        { value: 15, name: "Bourgogne-Franche-Comté" },
        { value: 16, name: "Nouvelle-Aquitaine" },
        { value: 17, name: "Pays de la Loire" }
      ]
    };
  },
  computed: {
    ...mapState(["filterParameters"]),
    value() {
      return this.regionOptions.find(
        option => option.value === this.filterParameters.region
      );
    }
  },
  methods: {
    ...mapActions([
      "getListings",
      "getMapListings",
      "updateFilters",
      "removeZipCode",
      "removeRegion",
      "resetFilters",
      "closeFilters"
    ]),
    filterChanged(parameters) {
      this.updateFilters(parameters);
      this.updateFilters({ name: "offset", value: 0 });
      this.getListings();
      this.getMapListings();
    },
    removeFilters() {
      this.resetFilters();
      this.getListings();
      this.getMapListings();
    },
    applyFilters() {
      this.closeFilters();
      this.updateFilters({ name: "offset", value: 0 });
      this.getListings();
      this.getMapListings();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.search-header-filters-container {
  display: block;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  background-color: white;
  padding: $space-2x;
  @media #{$desktop-and-up} {
    padding: $space-2x $space-4x;
  }
}

.search-header-filters-open {
  margin-top: $space-9x;
  @media #{$tablet-landscape-and-up} {
    margin-top: 0;
  }
}
.search-header-filters {
  border-radius: 4px;
  border: 1px solid $light-grey;
  @media #{$tablet-portrait-and-up} {
    display: inline-block;
  }
}

.search-header-filters-zip-code {
  border: 1px solid $black;
  border-radius: 18px;
  background-color: #ffffff;
  color: $black;
  font-size: 13px;
  line-height: 26px;
  font-weight: 600;
  padding: 0 $space-2x;
  margin-right: $space-1x;

  span {
    line-height: 13px;
    vertical-align: middle;
  }
}

.has-cross {
  padding: 0 $space-1x 0 $space-2x;
}

.search-header-filters-zip-codes {
  margin-top: $space-3x;
}

.filters-mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-bottom: 1px solid $light-grey;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  padding: 0 $space-3x;
  align-items: center;

  @media #{$tablet-landscape-and-up} {
    display: none;
  }

  .filter-mobile-reset,
  .filter-mobile-close {
    text-decoration: none;
  }

  .filter-mobile-reset {
    color: $pink;
    font-size: 14px;
    font-weight: 600;
  }

  h2 {
    font-size: 20px;
    margin: $space-3x 0;
  }
}
</style>
