<template>
  <div class="number-of-rooms">
    <h3>{{ title }}</h3>
    <div class="number-of-rooms-radio">
      <div v-for="n in 5" :key="`${name}-${n}`">
        <input
          type="radio"
          :name="name"
          :id="`${name}-${n}`"
          :value="n"
          :checked="selected === n"
          @change="triggerChange(n)"
        />
        <label :for="`${name}-${n}`">{{ n }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberOfRoomsSelector",
  props: {
    title: String,
    name: String,
    selected: Number
  },
  methods: {
    triggerChange(selectedValue) {
      this.$emit("change", selectedValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.number-of-rooms-radio {
  display: flex;
  input {
    display: none;
  }

  input:checked + label {
    background-color: $pink;
    border-color: $pink;
    color: white;
  }

  label {
    border: 1px solid $grey;
    width: 36px;
    line-height: 36px;
    background-color: white;
    border-radius: 2px;
    color: $grey;
    transition: background-color 300ms;
    text-align: center;
    margin-right: $space-2x;
    display: block;
    cursor: pointer;

    &:hover {
      background-color: $light-pink;
    }
  }
}
</style>
