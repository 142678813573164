<template>
  <div class="toggle-map">
    <input
      type="radio"
      name="map-list-switch"
      id="show-list"
      @change="switchView()"
      checked="true"
    />
    <label for="show-list">
      <List :width="20" :color="showSearchList ? 'white' : '#251540'" />
    </label>

    <input
      type="radio"
      name="map-list-switch"
      id="show-map"
      @change="switchView()"
    />
    <label for="show-map">
      <Map :width="20" :color="showSearchList ? '#251540' : 'white'" />
    </label>
  </div>
</template>

<script>
import Map from "@/components/Icons/Map.vue";
import List from "@/components/Icons/List.vue";

import { mapState, mapActions } from "vuex";

export default {
  name: "MapListSwitch",
  computed: mapState(["showSearchList"]),
  methods: mapActions(["switchView"]),
  components: {
    Map,
    List
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.toggle-map {
  border: 1px solid $black;
  border-radius: 3px;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  background-color: $light-grey;

  @media #{$tablet-landscape-and-up} {
    display: none;
  }

  input {
    display: none;
  }

  label {
    padding: $space-1x;
    display: inline-block;
    cursor: pointer;
    text-align: center;
  }

  svg {
    vertical-align: middle;
  }

  input:checked + label {
    background-color: $black;
    color: white;
  }
}
</style>
