<template>
  <div class="location-filter-input" v-click-outside="closeAutoComplete">
    <span><Search width="15" color="#939393"/></span>
    <input
      type="text"
      :placeholder="placeholder"
      name="zip_codes"
      value=""
      v-model="inputValue"
      @input="updateAutoComplete($event.target.value)"
      @focus="openAutoComplete"
      @keyup.enter="event => selectCode(undefined, event)"
      @keydown.up="focusPrevious"
      @keydown.down="focusNext"
      spellcheck="false"
      autocomplete="off"
    />
    <ul
      class="location-filter-autocomplete"
      v-show="autoCompleteOpen"
      ref="select"
    >
      <li
        v-for="(zipCode, index) in autocompleteZipCodes"
        :value="zipCode.value"
        :key="`${zipCode.value}-${index}`"
        v-show="filterParameters.zip_codes.indexOf(zipCode.value) === -1"
        :class="{ selected: selectedInput === index }"
        @mouseover="focus(index)"
        :ref="`element-${index}`"
      >
        <a href="" @click.prevent="selectCode(zipCode)">{{ zipCode.title }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import { Search } from "@/components/Icons";
import { mapActions, mapState } from "vuex";
import { ZIP_CODE_INPUT_PLACEHOLDERS } from "@/constants";

export default {
  name: "FiltersRegionsInput",
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      autoCompleteOpen: false,
      selectedInput: -1,
      inputValue: ""
    };
  },
  components: {
    Search
  },
  computed: {
    ...mapState(["autocompleteZipCodes", "filterParameters"]),
    region() {
      return this.filterParameters.region;
    },
    placeholder() {
      return this.$t(ZIP_CODE_INPUT_PLACEHOLDERS(this.region));
    }
  },
  methods: {
    ...mapActions(["getAutocompleteValues", "selectZipCode"]),
    selectCode(zipCode, event) {
      if (typeof zipCode === "undefined") {
        this.keyenter = true;
        event.preventDefault();
        if (this.selectedInput === -1) {
          const typedZipCode = this.autocompleteZipCodes.find(
            zipcode => event.target.value === zipcode.value
          );
          if (typeof typedZipCode !== "undefined") {
            this.selectZipCode(typedZipCode);
          }
        } else {
          this.selectZipCode(this.autocompleteZipCodes[this.selectedInput]);
        }
      } else {
        this.selectZipCode(zipCode);
      }

      if (this.selectedInput !== -1) {
        this.selectedInput -= 1;
      }
    },
    updateAutoComplete(value) {
      this.openAutoComplete();
      this.getAutocompleteValues(value.trim());
      this.selectedInput = -1;
    },
    openAutoComplete() {
      if (!this.autoCompleteOpen) {
        this.autoCompleteOpen = true;
      }
    },
    closeAutoComplete(event) {
      if (!event.target.classList.contains("search-header-filters-zip-code")) {
        this.autoCompleteOpen = false;
      }
    },
    focusPrevious() {
      if (this.selectedInput <= 0) {
        this.selectedInput = this.autocompleteZipCodes.length - 1;
      } else {
        do {
          this.selectedInput -= 1;
          if (this.selectedInput === -1) {
            this.selectedInput = this.autocompleteZipCodes.length - 1;
          }
          // eslint-disable-next-line max-len
        } while (
          this.filterParameters.zip_codes.indexOf(
            this.autocompleteZipCodes[this.selectedInput].value
          ) !== -1
        );
      }
      this.scrollElementToView();
    },
    focusNext() {
      if (this.autocompleteZipCodes.length - 1 === this.selectedInput) {
        this.selectedInput = 0;
      } else {
        do {
          this.selectedInput += 1;
          if (this.autocompleteZipCodes.length === this.selectedInput) {
            this.selectedInput = 0;
          }
          // eslint-disable-next-line max-len
        } while (
          this.filterParameters.zip_codes.indexOf(
            this.autocompleteZipCodes[this.selectedInput].value
          ) !== -1
        );
      }
      this.scrollElementToView();
    },
    focus(index) {
      this.selectedInput = index;
    },
    scrollElementToView() {
      const element = this.$refs[`element-${this.selectedInput}`][0];
      this.$refs.select.scrollTop = element.offsetTop;
    }
  },
  watch: {
    region(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.inputValue = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.location-filter-input {
  font-size: 14px;
  display: inline-block;
  color: $grey;
  background-color: $light-grey;
  width: 100%;
  text-align: left;
  position: relative;
  @media #{$tablet-portrait-and-up} {
    width: auto;
  }

  span,
  input {
    display: inline-block;
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: $space-3x;
  }
  input {
    border: none;
    background-color: transparent;
    padding: $space-2x $space-1x $space-2x $space-6x;
    min-width: 250px;
    line-height: 18px;
    width: calc(100% - #{$space-7x});
    height: auto;
    box-sizing: initial;
  }
}

.location-filter-autocomplete {
  margin: 0;
  padding: 0;
  position: absolute;
  list-style-type: none;
  color: $black;
  max-height: 200px;
  overflow: auto;
  background-color: white;
  min-width: 255px;
  z-index: 99;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  margin-top: 1px;

  a {
    display: block;
    text-decoration: none;
    color: $black;
    padding: 0 $space-3x;
    font-weight: 600;
    line-height: 40px;
  }
}

.selected a {
  background-color: $light-grey;
}
</style>
