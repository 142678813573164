<template>
  <div class="listing-services">
    <section v-if="listingServices">
      <h3>{{ $t("services") }}</h3>
      <Collapsible
        :collapsedHeight="maxServiceHeight"
        :showMoreText="seeMoreServices"
        :showLessText="seeLess"
      >
        <ul>
          <li
            v-for="(service, index) in listingServices"
            :key="'service-' + index"
          >
            {{ service }}
          </li>
        </ul>
      </Collapsible>
    </section>
    <section v-if="buildingServices">
      <h3>{{ $t("building") }}</h3>
      <Collapsible
        :collapsedHeight="maxServiceHeight"
        :showMoreText="seeMoreBuilding"
        :showLessText="seeLess"
      >
        <ul>
          <li
            v-for="(service, index) in buildingServices"
            :key="'building-service-' + index"
          >
            {{ service }}
          </li>
        </ul>
      </Collapsible>
    </section>
    <section v-if="Object.keys(heatingServices).length">
      <h3>{{ $t("heating") }}</h3>
      <Collapsible :collapsedHeight="maxServiceHeight">
        <ul>
          <li
            v-for="(service, key) in heatingServices"
            :key="'heating-service-' + key"
          >
            {{ $t(`heating_services.${key}`, { service }) }}
          </li>
        </ul>
      </Collapsible>
    </section>
  </div>
</template>

<script>
import Collapsible from "@/components/Collapsible.vue";

export default {
  name: "PropertyServices",
  props: {
    listingServices: Array,
    buildingServices: Array,
    heatingServices: Object
  },
  data() {
    const linesToDisplay = 3;
    return {
      linesToDisplay,
      maxServiceHeight: 29 * linesToDisplay // 29 is the line height
    };
  },
  computed: {
    seeMoreServices() {
      if (typeof this.listingServices === "undefined") {
        return "";
      }
      const hiddenLines = this.listingServices.length - this.linesToDisplay;
      return this.$tc("see_more", hiddenLines);
    },
    seeMoreBuilding() {
      if (typeof this.buildingServices === "undefined") {
        return "";
      }
      const hiddenLines = this.buildingServices.length - this.linesToDisplay;
      return this.$tc("see_more", hiddenLines);
    },
    seeLess() {
      return this.$t("see_less");
    }
  },
  components: {
    Collapsible
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.listing-services {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $space-4x;

  section {
    flex-basis: 50%;
    margin-bottom: $space-2x;
  }

  ul {
    margin: 0;
    list-style: none;
    padding: 0;
  }

  li {
    line-height: 29px;
    font-size: 15px;
    &::before {
      content: "";
      background-image: url("../../assets/images/check.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 14px;
      height: 14px;
      margin-right: $space-1x;
      display: inline-block;
      border: 1px solid $pink;
      border-radius: 3px;
    }
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: $space-1x;
  }
}
</style>
