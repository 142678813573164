<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 9 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="(OLD)Filtre-UX-improvement"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g
        id="Liste-Copy-13"
        transform="translate(-152.000000, -156.000000)"
        :stroke="color"
        stroke-width="1.576923"
      >
        <g id="Group-5" transform="translate(0.000000, 136.000000)">
          <g id="Region" transform="translate(70.000000, 6.000000)">
            <polyline
              id="ic_arrow"
              :transform="
                `translate(86.500000, 19.000000) ${rotation} translate(-86.500000, -19.000000) `
              "
              points="83 17 86.5 21 90 17"
            ></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import props from "@/components/Icons/props";

export default {
  props: {
    ...props,
    direction: String
  },
  name: "Arrow",
  computed: {
    rotation() {
      switch (this.direction) {
        case "left":
          return "rotate(90.000000)";
        case "up":
          return "rotate(180.000000)";
        case "right":
          return "rotate(270.000000)";
        case "down":
        default:
          return "";
      }
    }
  }
};
</script>
