<template>
  <div class="listing-cards">
    <div
      class="listing-cards-container"
      v-for="listing in listings"
      :key="'listing' + listing.slug"
      @mouseenter="setHighlightListing(listing.slug)"
      @mouseleave="removeHighlightListing"
    >
      <ListingCard :listing="listing" :openInNewTab="true" />
    </div>
    <div
      v-show="listings.length === 0 && !isFetchingListings"
      class="listing-cards-no-results"
    >
      <HouseSearch color="#000000" :width="62" />
      <h3>{{ $t("no_results_found") }}</h3>
      <p>{{ $t("create_alert_text") }}</p>
      <div class="listing-cards-no-results-ctas">
        <a :href="CREATE_ALERT_PATH" class="button-primary" target="_blank">{{
          $t("create_alert")
        }}</a>
        <button class="button-secondary" @click="removeFilters">
          {{ $t("see_all_results") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ListingCard from "@/components/ListingCard.vue";
import { HouseSearch } from "@/components/Icons";

import { mapState, mapActions } from "vuex";

import { CREATE_ALERT_PATH } from "@/constants";

export default {
  name: "SearchList",

  components: {
    ListingCard,
    HouseSearch
  },

  data() {
    return {
      CREATE_ALERT_PATH
    };
  },

  computed: {
    ...mapState(["listings", "isFetchingListings", "filterParameters"]),

    alertPath() {
      const {
        max_price,
        types,
        bedroom_count,
        room_count,
        min_surface,
        criteria,
        zip_codes
      } = this.filterParameters;
      const paramName = "buyer_property_search";
      let params = [];
      if (max_price > 0) {
        params.push(`${paramName}[max_price]=${max_price}`);
      }
      if (
        types.length &&
        !(types.includes("flat") && types.includes("house"))
      ) {
        if (types.includes("flat")) {
          params.push(`${paramName}[property_type]=property_is_apartment`);
        } else if (types.includes("house")) {
          params.push(`${paramName}[property_type]=property_is_house`);
        }
      }
      if (bedroom_count > 0) {
        params.push(`${paramName}[min_bedrooms_count]=${bedroom_count}`);
      }
      if (min_surface > 0) {
        params.push(`${paramName}[min_surface]=${min_surface}`);
      }
      if (room_count > 0) {
        params.push(`${paramName}[min_rooms_count]=${room_count}`);
      }
      if (criteria.length) {
        criteria.forEach(c => {
          switch (c) {
            case "parking":
              params.push(`${paramName}[property_preference_parking]=2`);
              break;
            case "exterior":
              params.push(`${paramName}[property_preference_exterior]=2`);
              break;
            case "elevator":
              params.push(`${paramName}[property_preference_elevator]=2`);
              break;
            case "caretaker":
              params.push(
                `${paramName}[property_preference_building_caretaker]=2`
              );
              break;
            case "cellar":
              params.push(`${paramName}[property_preference_cellar]=2`);
              break;
            case "high_floor":
              params.push(`${paramName}[floor_type]=2`);
              break;
          }
        });
      }
      if (zip_codes.length) {
        params.push(`${paramName}[zip_code_ids]=${zip_codes}`);
      }
      return `${CREATE_ALERT_PATH}${
        params.length ? "?" + params.join("&") : ""
      }`;
    }
  },

  methods: {
    ...mapActions([
      "setHighlightListing",
      "removeHighlightListing",
      "resetFilters",
      "getListings",
      "getMapListings"
    ]),

    removeFilters() {
      this.resetFilters();
      this.getListings();
      this.getMapListings();
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.listing-cards {
  display: flex;
  flex-wrap: wrap;
  margin-right: $space-2x;
  @media #{$desktop-and-up} {
    margin-left: $space-2x;
    margin-right: $space-4x;
  }
}

.listing-cards-container {
  background-color: white;
  border-radius: 4px;
  overflow: auto;
  margin: 0 0 $space-2x $space-2x;
  width: calc(100% - #{$space-2x});
  @media #{$tablet-portrait-and-up} {
    width: calc(50% - #{$space-2x});
  }
  @media #{$desktop-and-up} {
    width: calc(33.33333% - #{$space-2x});
  }
  &:hover {
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
  }
}

.listing-cards-no-results {
  text-align: center;
  max-width: 520px;
  padding-left: $space-2x;
  margin: $space-6x auto;

  h3 {
    margin-top: $space-6x;
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }
}

.listing-cards-no-results-ctas {
  margin-top: $space-6x;

  @media #{$tablet-portrait-and-up} {
    display: flex;
    justify-content: space-around;
  }

  button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    @media #{$tablet-portrait-and-up} {
      display: inline-block;
    }
  }
  .button-secondary {
    margin-top: $space-3x;
    @media #{$tablet-portrait-and-up} {
      margin-top: 0;
    }
  }
}
</style>
