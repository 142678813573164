<template>
  <button class="show-filters" @click="openFilters">
    <span><Search width="15" color="#939393"/></span>{{ $t("show_filters") }}
  </button>
</template>

<script>
import { Search } from "@/components/Icons";
import { mapActions } from "vuex";

export default {
  name: "FiltersButton",
  components: {
    Search
  },
  methods: mapActions(["openFilters"])
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.show-filters {
  border: none;
  background: transparent;
  font-size: 14px;
  padding: $space-2x $space-3x;
  color: $grey;
  background-color: $light-grey;
  width: 100%;
  cursor: pointer;
  text-align: left;
  @media #{$tablet-portrait-and-up} {
    width: auto;
    padding-right: 127px; // to be iso with filter input
  }

  span {
    margin-right: $space-1x;
  }
}
</style>
