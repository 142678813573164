<template>
  <div class="nav-link-wrap" @click="openDropdown = !openDropdown">
    <div class="dropdown-flex">
      <div>{{ item.name }}</div>
      <img
        src="https://assets.website-files.com/6447f3d606511b118ac3f9fd/6447f3d606511bdd62c3fc10_chevron-down-grey.svg"
        alt=""
        class="dropdown-icon"
      />
    </div>
    <div
      class="custom-dropdown-list"
      :class="{ 'custom-dropdown-list--open': openDropdown }"
    >
      <template v-if="item.sublinks">
        <a
          v-for="sublink in item.sublinks"
          :key="sublink.path"
          :href="sublink.path"
          class="dropdown-link"
        >
          <div class="nav-content-wrap">
            <div class="dropdown-title">{{ sublink.name }}</div>
            <div class="text-block-72">{{ sublink.subtitle }}</div>
          </div>
        </a>
      </template>

      <div class="dropdown-block" v-if="item.frequently_searched">
        <div class="dropdown-title-2">{{ $t("frequently_searched") }}</div>
        <a
          v-for="sublink in item.frequently_searched"
          :key="sublink.path"
          :href="sublink.path"
          class="nav-blog-item w-inline-block"
          ><div class="nav-link-blog-2">{{ sublink.name }}</div></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderNavDropdown",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      openDropdown: false
    };
  }
};
</script>

<style lang="scss" scoped>
.nav-link-wrap {
  margin-right: 8px;
  padding: 24px 10px;
  flex-direction: column;
  align-items: center;
  color: #000000;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  text-transform: capitalize;
  box-sizing: border-box;

  @media screen and (max-width: 991px) {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  @media screen and (max-width: 479px) {
    margin-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.dropdown-flex {
  display: flex;
  padding-bottom: 16px;

  @media screen and (min-width: 992px) {
    padding-bottom: 0;
  }
}
.dropdown-icon {
  display: block;
  width: 10px;
  margin-top: 2px;
  margin-left: 8px;
}

.custom-dropdown-list {
  flex-direction: column;
  flex: 0 0 auto;
  border-radius: 8px;
  background-color: #fafafa;
  color: #f8f8f8;
  font-weight: 700;

  display: none;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-20px);
  transform-style: preserve-3d;
  transition: all 200ms ease-in-out;

  @media screen and (min-width: 992px) {
    display: flex;
    position: absolute;
    left: auto;
    top: 100%;
    z-index: auto;
    min-width: 120px;
    box-shadow: 0 16px 30px 0 rgba(13, 15, 25, 0.5);
  }

  &--open {
    @media screen and (max-width: 991px) {
      display: flex;
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
}

.nav-link-wrap:hover {
  .custom-dropdown-list {
    @media screen and (min-width: 992px) {
      display: flex;
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
}

.dropdown-link {
  width: 100%;
  padding: 12px 24px;
  flex: 0 0 auto;
  border-radius: 8px;
  background-color: #fafafa;
  transition: background-color 300ms ease, color 300ms ease;
  color: rgba(86, 96, 115, 0.7);
  font-weight: 700;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;

  @media screen and (min-width: 1440px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    padding: 20px;
  }

  &:hover {
    background-color: rgba(86, 96, 115, 0.2);

    @media screen and (min-width: 1440px) {
      border-radius: 8px;
      background-color: rgba(51, 51, 51, 0.1);
    }
  }
}

.dropdown-title {
  margin-bottom: 2px;
  color: #333;
  font-size: 15px;
  text-transform: none;

  @media screen and (min-width: 1440px) {
    color: #283338;
    text-transform: none;
  }
}

.text-block-72 {
  color: #333;
  font-weight: 400;
  text-transform: none;

  @media screen and (min-width: 1440px) {
    color: #20222b;
    font-weight: 400;
    text-transform: none;
  }
}

.dropdown-block {
  position: relative;
  z-index: 25;
  width: 100%;
  padding: 20px;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #fafafa;
  color: #283338;
  font-size: 16px;
  text-decoration: none;
  box-sizing: border-box;
}

.dropdown-title-2 {
  margin-bottom: 2px;
  font-size: 15px;
  text-transform: none;

  @media screen and (min-width: 1440px) {
    color: #333;
    text-transform: none;
  }
}

.nav-blog-item {
  color: #ff566a;

  display: flex;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;
  text-transform: none;

  &:hover {
    color: #ed9473;
    text-decoration: underline;
  }
}
.nav-link-blog-2 {
  color: #ed9473;
  font-size: 14px;
  white-space: normal;
}
</style>
