<template>
  <a :href="item.path" class="nav-link-wrap">
    {{ item.name }}
  </a>
</template>

<script>
export default {
  name: "HeaderNavItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-link-wrap {
  margin-left: 0;
  transition: color 200ms ease-in-out;

  max-width: 100%;
  display: inline-block;

  margin-right: 8px;
  padding: 24px 10px;
  flex-direction: column;
  align-items: center;
  color: #000000;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;

  box-sizing: border-box;
}
@media screen and (max-width: 991px) {
  .nav-link-wrap {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
@media screen and (max-width: 767px) {
  .nav-link-wrap {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
@media screen and (max-width: 479px) {
  .nav-link-wrap {
    margin-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
