<template>
  <div class="listing-card">
    <swiper
      :options="swiperOptions"
      v-if="showCarousel"
      class="listing-card-image-container"
    >
      <swiper-slide
        v-for="(img, index) in listing.low_quality_media"
        :key="`${listing.slug}-${index}`"
      >
        <router-link
          :to="{ name: 'property', params: { slug: listing.slug } }"
          :target="openInNewTab ? '_blank' : ''"
          class="listing-card-gallery-link swiper-lazy"
          :style="{ 'background-image': `url(${img})` }"
        />
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
    <div v-else class="listing-card-image-container">
      <router-link
        :to="{ name: 'property', params: { slug: listing.slug } }"
        :target="openInNewTab ? '_blank' : ''"
      >
        <img
          class="listing-card-image"
          v-lazy="listing.low_quality_media[0]"
          alt=""
        />
      </router-link>
    </div>
    <router-link
      :to="{ name: 'property', params: { slug: listing.slug } }"
      :target="openInNewTab ? '_blank' : ''"
    >
      <div class="listing-card-info">
        <Price :is-sold="listing.is_sold" :price="listing.price" />
        <p class="listing-type">
          {{ listing.type }}<span v-if="lightCard"> {{ lightCardTitle }}</span>
        </p>
        <p class="listing-address">{{ listing.address }}</p>
        <ul class="listing-card-amenities" v-if="!lightCard">
          <li class="listing-surface">
            {{ $n(listing.surface, "surface", "fr-FR") }} m²
          </li>
          <li>
            {{ $tc("room", listing.room_count) }}
          </li>
          <li>
            {{
              $tc("bedroom", listing.bedroom_count, {
                count: listing.bedroom_count
              })
            }}
          </li>
          <li>
          </li>
        </ul>
      </div>
    </router-link>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import Price from "@/components/Price.vue";

export default {
  name: "ListingCard",
  props: {
    listing: Object,
    showCarousel: {
      type: Boolean,
      default: true
    },
    lightCard: {
      type: Boolean,
      default: false
    },
    openInNewTab: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next", // TODO: change class name for custom arrows
          prevEl: ".swiper-button-prev"
        },
        lazy: {
          loadPrevNext: true
        }
      }
    };
  },
  computed: {
    lightCardTitle() {
      return `${this.$tc("room", this.listing.room_count)} de ${this.$n(
        this.listing.surface,
        "surface",
        "fr-FR"
      )}m²`;
    }
  },
  components: {
    swiper,
    swiperSlide,
    Price
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.listing-card {
  width: 100%;
  background-color: white;
}

.listing-card-info {
  padding: $space-2x;

  h2,
  p,
  ul {
    margin-top: 0;
  }

  ul {
    margin-bottom: 0;
  }
}
.listing-card a {
  text-decoration: none;
  color: $black;
}
.listing-address {
  margin-bottom: 1em;
}
.listing-card-gallery-link {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.listing-card-amenities {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;

  li {
    margin-right: $space-1x;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.listing-type {
  margin-bottom: $space-1x;
  font-weight: 600;
}

.listing-surface {
  font-weight: 600;
}

.listing-card-image {
  width: 100%;
  height: auto;
  display: block;
}

.is-map-card .listing-card-image {
  min-height: 200px;
  min-width: 300px;
}

.swiper-slide {
  overflow: hidden;
  height: 0;
  padding-top: calc(200% / 3);
}

.swiper-button-prev {
  background-image: url("../assets/images/arrow-left.svg");
  left: 0;
  margin-left: $space-1x;
}
.swiper-button-next {
  background-image: url("../assets/images/arrow-right.svg");
  right: 0;
  margin-right: $space-1x;
}
.swiper-button-prev,
.swiper-button-next {
  height: 100%;
  min-width: 40px;
  top: 0;
  margin: 0;
  outline: none;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.listing-card.mini {
  display: flex;

  &.hidden-large {
    @media #{$tablet-landscape-and-up} {
      display: none;
    }
  }

  .listing-card-image-container {
    flex-basis: 33.33333%;
    min-width: 130px;
    align-self: center;
  }

  .listing-address {
    display: none;
    @media #{$tablet-portrait-and-up} {
      display: block;
    }
  }

  .listing-card-info {
    display: flex;
    flex-wrap: wrap;
    padding: $space-1x;
    @media #{$tablet-portrait-and-up} {
      display: block;
      padding: $space-2x;
    }
  }

  .listing-type {
    display: block;
    flex-basis: 100%;
    order: 0;
  }

  .listing-price {
    order: 1;
    margin-bottom: 0;
    @media #{$tablet-portrait-and-up} {
      margin-bottom: $space-2x;
    }
  }
}
</style>
