<template>
  <nav role="navigation">
    <div class="nav-menu-content">
      <div class="nav-links-wrapper">
        <component
          :is="item.path ? 'header-nav-item' : 'header-nav-dropdown'"
          v-for="item in $t('header.links')"
          :key="item.name"
          :item="item"
        />

        <a :href="$t('header.button.path')" class="button">{{
          $t("header.button.name")
        }}</a>
      </div>
    </div>
  </nav>
</template>

<script>
import HeaderNavItem from "@/components/Header/HeaderNavItem.vue";
import HeaderNavDropdown from "@/components/Header/HeaderNavDropdown.vue";
export default {
  name: "HeaderNav",
  components: {
    HeaderNavItem,
    HeaderNavDropdown
  }
};
</script>

<style lang="scss" scoped>
.button {
  display: inline-flex;
  background-color: $color-secondary;
  color: white;
  text-decoration: none;
  border-radius: 0.25rem;
  padding: 0.8rem 1.3rem;
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
  line-height: 1.6;
  margin-left: 10px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-right: auto;

  @media screen and (min-width: 992px) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.nav-menu-content {
  margin-top: 56px;

  @media screen and (min-width: 992px) {
    margin-top: 7px;
  }
}

.nav-links-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 16px;

  @media screen and (min-width: 992px) {
    flex-direction: row;
    margin-bottom: 0;
  }
}
</style>
