import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/utils/i18n";
import * as VueGoogleMaps from "vue2-google-maps";
import vClickOutside from "v-click-outside";
import VueScrollTo from "vue-scrollto";
import VeeValidate from "vee-validate";
import validationMessages from "vee-validate/dist/locale/fr";
import { GOOGLE_MAPS_API_KEY } from "@/constants";
import VueIntercom from "vue-intercom";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

Vue.use(VeeValidate, {
  // eslint-disable-next-line max-len
  inject: false, // to prevent clash with VueGoogleMap we must inject at the component level. See VisitRequest.vue
  classes: true,
  i18nRootKey: "validations", // customize the root path for validation messages.
  i18n,
  dictionary: {
    "fr-FR": validationMessages
  }
});
Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAPS_API_KEY
  },
  autobindAllEvents: true
});
Vue.use(VueScrollTo);
Vue.use(vClickOutside);

Vue.use(VueIntercom, { appId: "ehsfwwsk" });

Vue.use(
  VueGtag,
  {
    config: { id: "UA-109494847-7" },
    bootstrap: false
  },
  router
);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
