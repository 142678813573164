<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 15 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Annonce"
        transform="translate(-1232.000000, -352.000000)"
        :fill="color"
        fill-rule="nonzero"
      >
        <g id="Photo" transform="translate(0.000000, 32.000000)">
          <g id="3D" transform="translate(1218.000000, 313.000000)">
            <g transform="translate(14.000000, 7.000000)" id="Shape">
              <path
                d="M14.5508982,3.93340353 L7.92215569,0.120821269 C7.65269461,-0.0402737563 7.31137725,-0.0402737563 7.04191617,0.120821269 L0.431137725,3.93340353 C0.161676647,4.09449855 0,4.38088971 0,4.68518031 L0,12.3103448 C0,12.6325349 0.161676647,12.918926 0.431137725,13.0621216 L7.05988024,16.8747039 C7.20359281,16.9463017 7.34730539,17 7.50898204,17 C7.65269461,17 7.81437126,16.9642011 7.94011976,16.8747039 L14.5688623,13.0621216 C14.8383234,12.9010266 15,12.6146354 15,12.3103448 L15,4.68518031 C15,4.36299026 14.8383234,4.07659911 14.5508982,3.93340353 Z M7.49101796,1.89286654 L12.3592814,4.68518031 L7.49101796,7.47749408 L2.62275449,4.68518031 L7.49101796,1.89286654 Z M1.74251497,6.20663332 L6.61077844,8.99894709 L6.61077844,14.6014741 L1.74251497,11.8091603 L1.74251497,6.20663332 Z M8.37125749,14.6014741 L8.37125749,8.99894709 L13.239521,6.20663332 L13.239521,11.8091603 L8.37125749,14.6014741 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "ThreeDimensions"
};
</script>
