<template>
  <Modal @close="closePropertyGallery" :isOpen="propertyGallery.isOpen">
    <div class="gallery-container">
      <swiper
        :options="swiperOptions"
        class="gallery"
        ref="carousel"
        @slideChange="slideChange"
      >
        <swiper-slide v-if="typeof displayedListing.video_url !== 'undefined'">
          <div class="iframe-container">
            <iframe
              :src="displayedListing.video_url"
              frameborder="0"
              allowfullscreen
            />
          </div>
        </swiper-slide>
        <swiper-slide
          v-for="(src, index) in displayedListing.high_quality_media"
          :key="`${displayedListing.slug}-${index}`"
        >
          <div
            :style="{ 'background-image': 'url(' + src + ')' }"
            class="swiper-lazy gallery-image"
          >
            <div class="swiper-lazy-preloader"></div>
          </div>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <div class="gallery-thumbs">
        <div
          v-if="typeof displayedListing.video_url !== 'undefined'"
          :class="['gallery-thumbs-image', { active: activeSlide === 0 }]"
          @click="setActiveSlide(0)"
        >
          <img :src="displayedListing.low_quality_media[0]" alt="" />
          <span class="three-d">3D</span>
        </div>
        <div
          v-for="(img, index) in displayedListing.low_quality_media"
          :key="`low-${displayedListing.slug}-${index}`"
          :class="[
            'gallery-thumbs-image',
            { active: index + firstImageIndex === activeSlide }
          ]"
          @click="setActiveSlide(index + firstImageIndex)"
        >
          <img :src="img" alt="" />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import "swiper/dist/css/swiper.css";
import Modal from "@/components/Layouts/Modal.vue";
import { mapActions, mapState } from "vuex";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  name: "GalleryModal",
  components: {
    Modal,
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        // loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        keyboard: {
          enabled: true,
          onlyInViewport: false
        },
        lazy: {
          loadPrevNext: true
        }
      },
      activeSlide: 0
    };
  },
  computed: {
    ...mapState(["propertyGallery", "displayedListing"]),
    swiper() {
      return this.$refs.carousel.swiper;
    },
    firstImageIndex() {
      return typeof this.displayedListing.video_url === "undefined" ? 0 : 1;
    }
  },
  watch: {
    propertyGallery(newValue, oldValue) {
      if (newValue.isOpen && !oldValue.isOpen) {
        this.activeSlide = newValue.show3DView ? 0 : this.firstImageIndex;
        this.setActiveSlide(this.activeSlide);
      }
    }
  },
  methods: {
    ...mapActions(["closePropertyGallery"]),
    setActiveSlide(index) {
      this.swiper.slideTo(index);
    },
    slideChange() {
      this.activeSlide = this.swiper.realIndex;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
img {
  width: 100%;
  height: auto;
  display: block;
}

.gallery-image {
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  background-position: top center;
  padding-bottom: 62%;

  @media #{$tablet-landscape-and-up} {
    padding-bottom: 0;
  }
}

.gallery-container {
  position: relative;
  height: 100%;
  @media screen and (orientation: landscape) {
    display: flex;
    max-width: calc(100% - #{$space-6x});
  }
  @media #{$tablet-landscape-and-up} {
    display: block;
    max-width: none;
  }
}

.gallery {
  top: 50%;
  transform: translateY(-50%);
  max-height: 100vh;
  max-width: 100%;
  @media screen and (orientation: landscape) {
    max-height: 90vh;
    width: 100%;
    margin-top: 5vh;
    max-width: calc(100% - 120px);
    top: 0;
    transform: none;
  }

  @media #{$tablet-landscape-and-up} {
    max-height: 80vh;
    height: 100%;
    max-width: 1200px;
    top: 0;
    transform: none;
    padding-top: $space-6x;
    margin-top: 0;
  }
}

.gallery-thumbs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  position: absolute;
  bottom: 0;
  max-width: 100%;

  @media screen and (orientation: landscape) {
    flex-direction: column;
    overflow-y: auto;
    max-height: 90vh;
    margin-top: calc(5vh - 3px); // border size
    position: relative;
  }

  @media #{$tablet-landscape-and-up} {
    flex-direction: row;
    overflow-y: hidden;
    margin: $space-2x auto;
    position: relative;
    max-width: 1200px;
  }
}
.gallery-thumbs-image {
  flex: 0 0 auto;
  width: 80px;
  margin-right: $space-1x;
  position: relative;
  cursor: pointer;

  img {
    border: 3px solid transparent;
    box-sizing: border-box;
    height: 100%;
  }

  &.active img {
    border-color: $pink;
  }
}

.iframe-container {
  padding-top: 56.25%;
  align-self: flex-start;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
}

.three-d {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 26px;
  font-weight: 600;
  background-color: rgba(255, 85, 106, 0.7);
  border-radius: 50%;
  width: 46px;
  line-height: 46px;
  text-align: center;
}

.swiper-button-prev,
.swiper-button-next {
  transform: translateY(-50%);
  background-color: rgba(37, 21, 64, 0.5);
  margin: 0;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  @media #{$tablet-landscape-and-up} {
    height: 60px;
    width: 60px;
    // background-color: transparent;
  }
}
.swiper-button-prev {
  background-image: url("../assets/images/arrow-left.svg");
  left: 0;
  margin-left: $space-1x;
}
.swiper-button-next {
  background-image: url("../assets/images/arrow-right.svg");
  right: 0;
  margin-right: $space-1x;
}
</style>
