<template>
  <Paginate
    v-model="currentPage"
    :page-count="pageCount"
    :click-handler="clickCallback"
    :container-class="'pagination'"
    :page-class="'page-item'"
    prev-text=""
    next-text=""
    prev-class="previous-page"
    next-class="next-page"
  >
  </Paginate>
</template>

<script>
import Paginate from "vuejs-paginate";

import { mapActions, mapState } from "vuex";

export default {
  name: "SearchPager",
  components: {
    Paginate
  },
  computed: {
    ...mapState(["listingCount", "filterParameters"]),
    pageCount() {
      return Math.ceil(this.listingCount / this.filterParameters.max_results);
    },

    currentPage: {
      get() {
        return (
          this.filterParameters.offset / this.filterParameters.max_results + 1
        );
      },
      set() {}
    }
  },
  methods: {
    ...mapActions(["getListings", "updateFilters"]),
    clickCallback(page) {
      this.updateFilters({
        name: "offset",
        value: this.filterParameters.max_results * (page - 1)
      });
      this.getListings();
      document.querySelector(".search-page-content").scrollTop = 0;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  font-weight: 600;
  padding: 0;
  margin: $space-5x 0;
}
.page-item {
  color: $pink;
  width: 28px;
  line-height: 28px;
  text-align: center;
  margin: 0 $space-1x;

  &.active {
    color: white;
    background-color: $pink;
    border-radius: 50%;
  }

  a {
    outline: none;
  }
}

.next-page,
.previous-page {
  border-radius: 50%;
  border: 1px solid $pink;
  width: 28px;
  line-height: 28px;
  text-align: center;
  margin: 0 $space-1x;
  a {
    display: block;
    height: 100%;
  }

  &.disabled {
    visibility: hidden;
  }
}
.next-page a::before,
.previous-page a::before {
  content: "";
  background-image: url("../../assets/images/arrow.svg");
  background-repeat: no-repeat;
  display: block;
  background-position: center;
  height: 100%;
}

.next-page a::before {
  transform: rotate(270deg);
}
.previous-page a::before {
  transform: rotate(90deg);
}
</style>
