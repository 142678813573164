import axios from "axios";
import { API_BASE_URL } from "@/constants";

export default () => {
  const params = {
    baseURL: API_BASE_URL,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };
  return axios.create(params);
};
