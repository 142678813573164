/* eslint-disable object-curly-newline */
export const DIAGNOSTIC_COLORS = {
  dpe: [
    { value: "A", color: "#00A170" },
    { value: "B", color: "#00BB54" },
    { value: "C", color: "#4AC57B" },
    { value: "D", color: "#FDEB00" },
    { value: "E", color: "#FFBC00" },
    { value: "F", color: "#FF882F" },
    { value: "G", color: "#EC0017" }
  ],
  ges: [
    { value: "A", color: "#A3DBFC" },
    { value: "B", color: "#8CB4D3" },
    { value: "C", color: "#7792B1" },
    { value: "D", color: "#606F8F" },
    { value: "E", color: "#4D5271" },
    { value: "F", color: "#393551" },
    { value: "G", color: "#281B35" }
  ]
};

export const DIAGNOSTIC_RANGES = [
  { value: "A", dpe: 70, ges: 6 },
  { value: "B", dpe: 110, ges: 11 },
  { value: "C", dpe: 180, ges: 30 },
  { value: "D", dpe: 250, ges: 50 },
  { value: "E", dpe: 330, ges: 70 },
  { value: "F", dpe: 420, ges: 100 },
  { value: "G", dpe: null, ges: null }
];

export const OLD_DIAGNOSTIC_RANGES = [
  { value: "A", dpe: 50, ges: 5 },
  { value: "B", dpe: 90, ges: 10 },
  { value: "C", dpe: 150, ges: 20 },
  { value: "D", dpe: 230, ges: 35 },
  { value: "E", dpe: 330, ges: 55 },
  { value: "F", dpe: 450, ges: 80 },
  { value: "G", dpe: null, ges: null }
];

export const PARIS_COORDINATES = {
  lat: 48.864716,
  lng: 2.349014
};

const NICE_COORDINATES = {
  lat: 43.7032932,
  lng: 7.1827775
};

const LYON_COORDINATES = {
  lat: 45.75801,
  lng: 4.8001016
};

const MONTPELLIER_COORDINATES = {
  lat: 43.5985,
  lng: 3.89687
};

const LILLE_COORDINATES = {
  lat: 50.629656,
  lng: 3.0702027
};

export const CITY_COORDINATES = [
  {
    region: 1,
    coordinates: PARIS_COORDINATES
  },
  {
    region: 2,
    coordinates: NICE_COORDINATES
  },
  {
    region: 3,
    coordinates: LYON_COORDINATES
  },
  {
    region: 5,
    coordinates: MONTPELLIER_COORDINATES
  },
  {
    region: 6,
    coordinates: LILLE_COORDINATES
  },
  {
    // Grand-Est
    region: 8,
    coordinates: {
      lat: 49.2068,
      lng: 4.0334
    }
  },
  {
    // Normandie
    region: 9,
    coordinates: {
      lat: 49.3877,
      lng: 1.1
    }
  },
  {
    // Bretagne
    region: 12,
    coordinates: {
      lat: 48.1172,
      lng: -1.6777
    }
  },
  {
    // Centre-Val de Loire
    region: 13,
    coordinates: {
      lat: 47.3941,
      lng: 0.6848
    }
  },
  {
    // Bourgogne-Franche-Comté
    region: 15,
    coordinates: {
      lat: 47.3166,
      lng: 5.0166
    }
  },
  {
    // Nouvelle-Aquitaine
    region: 16,
    coordinates: {
      lat: 43.4929,
      lng: -1.4748
    }
  },
  {
    // Pays de la Loire
    region: 17,
    coordinates: {
      lat: 47.4784,
      lng: -0.5631
    }
  }
];

export const MAX_RESULTS_PER_PAGE = 12;

export const MAX_PRICE = 10000000;

export const DEFAULT_FILTERS = {
  departments: [],
  region: 1,
  max_results: MAX_RESULTS_PER_PAGE,
  offset: 0,
  zip_codes: [],
  types: [],
  min_price: 0,
  max_price: MAX_PRICE,
  min_surface: undefined,
  max_surface: undefined,
  room_count: 0,
  bedroom_count: 0,
  include_sold_properties: false,
  order: "recent_desc",
  criteria: [],
  heating_mechanism: undefined,
  heating_mode: undefined,
  heating_type: undefined
};

export const ZIP_CODE_INPUT_PLACEHOLDERS = region => {
  switch (region) {
    case 1:
      return "search_input_placeholder";
    case 2:
      return "search_input_placeholder_nice";
    case 3:
      return "search_input_placeholder_lyon";
    case 4:
      return "search_input_placeholder_strasbourg";
    case 5:
      return "search_input_placeholder_montpellier";
    case 6:
      return "search_input_placeholder_lille";
    case 8:
      return "search_input_placeholder_reims";
    case 9:
      return "search_input_placeholder_rouen";
      case 11:
      return "search_input_placeholder_grenoble";
      case 12:
      return "search_input_placeholder_rennes";
      case 13:
      return "search_input_placeholder_tours";
      case 15:
      return "search_input_placeholder_dijon";
      case 16:
      return "search_input_placeholder_bayonne";
    default:
      return "search_input_placeholder";
  }
};

export const CREATE_ALERT_PATH = "https://www.liberkeys.com/alerte-immobiliere";
export const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
export const API_BASE_URL = process.env.VUE_APP_API_URL;
