<template>
  <h2 class="listing-price" :class="{ sold: isSold }">
    <span v-if="isSold && displayIsSold">{{ $t("sold") }}</span>
    {{ $n(price, "currency", "fr-FR") }}
  </h2>
</template>

<script>
export default {
  name: "Price",
  props: {
    isSold: Boolean,
    displayIsSold: {
      type: Boolean,
      default: true
    },
    price: Number
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.listing-price {
  color: $pink;
  margin-bottom: $space-2x;
  &.sold {
    color: $black;
  }
  span {
    color: white;
    background-color: $black;
    padding: 0 $space-1x;
    margin-right: $space-1x;
  }
}
</style>
