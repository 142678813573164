<template>
  <div class="modal" v-show="isOpen" @click="closeModalOnTarget">
    <button @click="closeModal" class="close-button">
      <Cross color="#FFFFFF" :width="17" />
    </button>
    <slot></slot>
  </div>
</template>

<script>
import { Cross } from "@/components/Icons";

export default {
  name: "Modal",
  components: {
    Cross
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    closeModalOnTarget(event) {
      if (event.target.classList.contains("gallery-container")) {
        this.closeModal();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(37, 21, 64, 0.9);
  overflow: auto;
}

.close-button {
  position: absolute;
  right: $space-2x;
  top: $space-2x;
  border: none;
  background-color: transparent;
  z-index: 999;
  svg {
    @media #{$tablet-portrait-and-up} {
      width: 30px;
    }
  }
}
</style>
