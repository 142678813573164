<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 43 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Filtres_V2"
        transform="translate(-39.000000, -929.000000)"
        :fill="color"
        fill-rule="nonzero"
      >
        <g id="Filter" transform="translate(14.000000, 73.000000)">
          <g id="ic_cave" transform="translate(25.000000, 856.000000)">
            <path
              d="M42.2037037,27.2 L33.4444444,27.2 L33.4444444,13.9312 L36.8629444,17.3656 C37.0182222,17.5216 37.2220741,17.6 37.4259259,17.6 C37.6297778,17.6 37.8336296,17.5216 37.9889074,17.3656 C38.3002593,17.0528 38.3002593,16.5472 37.9889074,16.2344 L22.0629815,0.2344 C21.7516296,-0.0784 21.2483704,-0.0784 20.9370185,0.2344 L5.01109259,16.2344 C4.69974074,16.5472 4.69974074,17.0528 5.01109259,17.3656 C5.16637037,17.5216 5.37022222,17.6 5.57407407,17.6 C5.77792593,17.6 5.98177778,17.5216 6.13705556,17.3656 L9.55555556,13.9312 L9.55555556,27.2 L0.796296296,27.2 C0.356740741,27.2 0,27.5576 0,28 C0,28.4424 0.356740741,28.8 0.796296296,28.8 L9.55555556,28.8 L9.55555556,31.2 C9.55555556,31.6424 9.9122963,32 10.3518519,32 L11.1481481,32 L11.1481481,32.8 C11.1481481,33.2424 11.5048889,33.6 11.9444444,33.6 L12.7407407,33.6 L12.7407407,34.4 C12.7407407,34.8424 13.0974815,35.2 13.537037,35.2 L14.3333333,35.2 L14.3333333,36 C14.3333333,36.4424 14.6900741,36.8 15.1296296,36.8 L15.9259259,36.8 L15.9259259,37.6 C15.9259259,38.0424 16.2826667,38.4 16.7222222,38.4 L17.5185185,38.4 L17.5185185,39.2 C17.5185185,39.6424 17.8752593,40 18.3148148,40 L32.6481481,40 C33.0885,40 33.4444444,39.6424 33.4444444,39.2 L33.4444444,28.8 L42.2037037,28.8 C42.6440556,28.8 43,28.4424 43,28 C43,27.5576 42.6440556,27.2 42.2037037,27.2 Z M11.1481481,12.3312 L21.5,1.9312 L31.8518519,12.3312 L31.8518519,27.2 L11.1481481,27.2 L11.1481481,12.3312 Z M31.8518519,38.4 L19.1111111,38.4 L19.1111111,37.6 C19.1111111,37.1576 18.7543704,36.8 18.3148148,36.8 L17.5185185,36.8 L17.5185185,36 C17.5185185,35.5576 17.1617778,35.2 16.7222222,35.2 L15.9259259,35.2 L15.9259259,34.4 C15.9259259,33.9576 15.5691852,33.6 15.1296296,33.6 L14.3333333,33.6 L14.3333333,32.8 C14.3333333,32.3576 13.9765926,32 13.537037,32 L12.7407407,32 L12.7407407,31.2 C12.7407407,30.7576 12.384,30.4 11.9444444,30.4 L11.1481481,30.4 L11.1481481,28.8 L31.8518519,28.8 L31.8518519,38.4 Z"
              id="Shape"
            ></path>
            <path
              d="M11.0868333,37.2944 C11.0462222,37.196 10.9880926,37.108 10.914037,37.0336 L9.32224074,35.4344 C9.01088889,35.1216 8.50762963,35.1216 8.19627778,35.4344 C7.88492593,35.7472 7.88492593,36.2528 8.19627778,36.5656 L8.42959259,36.8 L5.57407407,36.8 C5.13451852,36.8 4.77777778,37.1576 4.77777778,37.6 C4.77777778,38.0424 5.13451852,38.4 5.57407407,38.4 L8.42959259,38.4 L8.19627778,38.6344 C7.88492593,38.9472 7.88492593,39.4528 8.19627778,39.7656 C8.35155556,39.9216 8.55540741,40 8.75925926,40 C8.96311111,40 9.16696296,39.9216 9.32224074,39.7656 L10.914037,38.1664 C10.9880926,38.092 11.0462222,38.004 11.0868333,37.9056 C11.1672593,37.7104 11.1672593,37.4896 11.0868333,37.2944 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "Cellar"
};
</script>
