<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="(OLD)Filtre-UX-improvement"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Liste-Copy-13"
        transform="translate(-276.000000, -140.000000)"
        :fill="color"
        fill-rule="nonzero"
      >
        <g id="Group-5" transform="translate(0.000000, 136.000000)">
          <g id="Nav" transform="translate(233.000000, 0.000000)">
            <g id="Map" transform="translate(43.200000, 4.571429)">
              <path
                d="M18.9875508,21.0285714 L0.843891147,21.0285714 C0.590723803,21.0285714 0.379751016,20.9402018 0.210972787,20.7192777 C0.0421945573,20.5425385 0,20.2774296 0,20.0123208 L1.30803128,9.89399894 C1.35022583,9.45215082 1.72997685,9.14285714 2.10972787,9.14285714 L5.147736,9.14285714 C5.61187613,9.14285714 5.99162714,9.54052045 5.99162714,10.0265534 C5.99162714,10.5125863 5.61187613,10.9102496 5.147736,10.9102496 L2.8692299,10.9102496 L1.77217141,19.3053638 L18.0592705,19.3053638 L16.9622121,10.9102496 L14.6415114,10.9102496 C14.1773713,10.9102496 13.7976203,10.5125863 13.7976203,10.0265534 C13.7976203,9.54052045 14.1773713,9.14285714 14.6415114,9.14285714 L17.6795195,9.14285714 C18.1014651,9.14285714 18.4390216,9.45215082 18.4812161,9.89399894 L19.7892474,20.0123208 C19.8314419,20.2774296 19.7470528,20.4983537 19.5782746,20.7192777 C19.4516909,20.9402018 19.2407181,21.0285714 18.9875508,21.0285714 Z"
                id="Path"
              ></path>
              <path
                d="M10.8,15.5428571 C10.5871622,15.5428571 10.3743243,15.4572216 10.2466216,15.3287682 C9.65067568,14.772137 4.5,9.93372688 4.5,6.50830382 C4.5,2.9116096 7.35202703,0 10.8,0 C14.247973,0 17.1,2.9116096 17.1,6.50830382 C17.1,9.93372688 11.9493243,14.772137 11.3959459,15.3287682 C11.2256757,15.5000394 11.0128378,15.5428571 10.8,15.5428571 Z M10.8,1.66989374 C8.24594595,1.66989374 6.16013514,3.85360094 6.16013514,6.50830382 C6.16013514,8.52073987 9.0972973,11.8605274 10.8,13.5304211 C12.4601351,11.8605274 15.4398649,8.52073987 15.4398649,6.50830382 C15.4398649,3.85360094 13.3540541,1.66989374 10.8,1.66989374 Z"
                id="Shape"
              ></path>
              <path
                d="M10.9058824,6.4 L9.79411765,6.4 C9.35735294,6.4 9,5.98857143 9,5.48571429 C9,4.98285714 9.35735294,4.57142857 9.79411765,4.57142857 L10.9058824,4.57142857 C11.3426471,4.57142857 11.7,4.98285714 11.7,5.48571429 C11.7,5.98857143 11.3426471,6.4 10.9058824,6.4 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "Map"
};
</script>
