<template>
  <GmapMap
    :zoom="10"
    class="map-search"
    :center="center"
    ref="searchMap"
    @click="closeDisplayedListing"
    :options="{
      zoomControl: true,
      zoomControlOptions: {
        position: zoomControlPosition
      },
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
      clickableIcons: false
    }"
  >
    <!-- <GmapCluster> -->
    <gmap-custom-marker
      v-for="(listing, index) in filteredMapListings"
      :key="index"
      :ref="listing.slug"
      alignment="top"
      @click.native.prevent="showListingInfo(listing)"
      :marker="{
        lat: listing.lat,
        lng: listing.lng
      }"
    >
      <div
        v-show="listing !== displayedListing"
        :class="[
          'map-listing-price',
          { 'map-listing-price-is-sold': listing.is_sold }
        ]"
      >
        {{ $n(listing.price / 1000) }}k €
      </div>
    </gmap-custom-marker>
    <!-- </GmapCluster> -->

    <gmap-custom-marker
      v-if="displayedListing"
      alignment="top"
      :marker="{
        lat: displayedListing.lat,
        lng: displayedListing.lng
      }"
      @click.native="preventClosing"
      :delayRepaint="50"
      class="map-listing-card"
    >
      <div class="map-listing-card-container">
        <ListingCard
          class="is-map-card"
          :listing="displayedListing"
          :openInNewTab="true"
          :showCarousel="false"
        />
      </div>
    </gmap-custom-marker>
  </GmapMap>
</template>

<script>
// eslint-disable-next-line
import GmapCustomMarker from 'vue2-gmap-custom-marker';

// import GmapCluster from 'vue2-google-maps/dist/components/cluster';

import ListingCard from "@/components/ListingCard.vue";
import { gmapApi } from "vue2-google-maps";

import { mapState } from "vuex";

import { CITY_COORDINATES, PARIS_COORDINATES } from "@/constants";

export default {
  name: "SearchMap",

  components: {
    GmapCustomMarker,
    // GmapCluster,
    ListingCard
  },

  props: {
    showMap: Boolean
  },

  data() {
    return {
      infoWinOpen: true,
      displayedListing: undefined,
      doNotCloseInfoBox: false
    };
  },

  computed: {
    ...mapState(["mapListings", "highlightListing", "filterParameters"]),
    google: gmapApi,
    center: {
      get() {
        const activeCity = CITY_COORDINATES.find(
          city => city.region === this.filterParameters.region
        );
        if (typeof activeCity !== "undefined") {
          return activeCity.coordinates;
        }
        return PARIS_COORDINATES;
      }
    },
    zoomControlPosition() {
      return this.google && this.google.maps.ControlPosition.LEFT_BOTTOM;
    },
    filteredMapListings() {
      return this.mapListings.filter(
        ({ is_address_anonymous }) => !is_address_anonymous
      );
    }
  },

  methods: {
    fitBounds() {
      if (this.mapListings.length > 0) {
        if (this.google) {
          const latlngbounds = new this.google.maps.LatLngBounds();
          this.mapListings.forEach(listing => {
            latlngbounds.extend({
              lat: listing.lat,
              lng: listing.lng
            });
          });
          this.$refs.searchMap.fitBounds(latlngbounds);
        }
      }
    },
    showListingInfo(listing) {
      this.displayedListing = listing;
      this.doNotCloseInfoBox = true;
      this.$refs.searchMap.panTo({
        lat: listing.lat,
        lng: listing.lng
      });
      this.$refs.searchMap.panBy(0, -100);
    },
    closeDisplayedListing() {
      if (this.doNotCloseInfoBox) {
        this.doNotCloseInfoBox = false;
      } else {
        this.displayedListing = undefined;
      }
    },
    preventClosing() {
      this.doNotCloseInfoBox = true;
    }
  },
  mounted() {
    this.$refs.searchMap.$mapPromise.then(() => this.fitBounds());
  },

  watch: {
    mapListings() {
      this.fitBounds();
      this.displayedListing = undefined;
    },
    showMap() {
      this.closeDisplayedListing();
      this.fitBounds();
    },
    highlightListing(oldValue, newValue) {
      if (oldValue === "") {
        if (typeof this.$refs[newValue] !== "undefined") {
          this.$refs[newValue][0].$el.classList.remove("highlight");
        }
      } else if (typeof this.$refs[oldValue] !== "undefined") {
        this.$refs[oldValue][0].$el.classList.add("highlight");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
$arrow-width: 6px;
$outer-arrow-width: $arrow-width + 1;

.map-search {
  height: calc(100vh - 128px);
  z-index: 1;
  margin-top: -72px;
  @media #{$tablet-landscape-and-up} {
    margin-top: 0;
    height: 100%;
  }
}

.map-listing-price {
  color: white;
  background-color: $pink;
  border: 1px solid white;
  font-size: 14px;
  line-height: 20px;
  padding: 0 $space-1x;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;

  &::after {
    content: "";
    width: 0;
    height: 0;
    bottom: -$arrow-width + 1;
    border-left: $arrow-width solid transparent;
    border-right: $arrow-width solid transparent;
    border-top: $arrow-width solid $pink;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &::before {
    content: "";
    width: 0;
    height: 0;
    bottom: -$outer-arrow-width + 1;
    border-left: $outer-arrow-width solid transparent;
    border-right: $outer-arrow-width solid transparent;
    border-top: $outer-arrow-width solid white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &.map-listing-price-is-sold {
    background-color: $black;

    &::after {
      border-top-color: $black;
    }
  }
}

.highlight {
  z-index: 51 !important; // Override gmap inline style
  .map-listing-price {
    background-color: white;
    color: $black;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);

    &::after {
      border-top-color: white;
    }
  }
}

.map-listing-card {
  z-index: 52 !important; // Override gmap inline style
}

.map-listing-card-container {
  max-width: 300px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
  &::after {
    content: "";
    width: 0;
    height: 0;
    bottom: -$arrow-width;
    border-left: $arrow-width solid transparent;
    border-right: $arrow-width solid transparent;
    border-top: $arrow-width solid white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &::before {
    content: "";
    width: 0;
    height: 0;
    bottom: -$outer-arrow-width;
    border-left: $outer-arrow-width solid transparent;
    border-right: $outer-arrow-width solid transparent;
    border-top: $outer-arrow-width solid rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
