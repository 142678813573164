<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 8 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Filters-V1/V2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Liste-Copy-17"
        transform="translate(-160.000000, -88.000000)"
        :fill="color"
        fill-rule="nonzero"
      >
        <g id="Group-6-Copy" transform="translate(115.000000, 79.000000)">
          <polygon
            id="+"
            transform="translate(49.000000, 13.000000) rotate(-315.000000) translate(-49.000000, -13.000000) "
            points="54 13.531401 49.5294118 13.531401 49.5294118 18 48.4705882 18 48.4705882 13.531401 44 13.531401 44 12.4927536 48.4705882 12.4927536 48.4705882 8 49.5294118 8 49.5294118 12.4927536 54 12.4927536"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "Cross"
};
</script>
