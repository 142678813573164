import Vue from "vue";
import VueI18n from "vue-i18n";
import localeFr from "./locales/fr.json";

Vue.use(VueI18n);

const numberFormats = {
  "fr-FR": {
    currency: {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0
    },
    surface: {
      style: "decimal",
      maximumFractionDigits: 2
    }
  }
};

const i18n = new VueI18n({
  locale: "fr-FR",
  messages: {
    "fr-FR": localeFr
  },
  numberFormats
});

export default i18n;
