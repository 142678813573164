<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="(OLD)Filtre-UX-improvement"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Liste-Copy-13"
        transform="translate(-243.000000, -177.000000)"
        :fill="color"
        fill-rule="nonzero"
      >
        <g id="Group-5" transform="translate(0.000000, 136.000000)">
          <g id="Nav" transform="translate(233.000000, 0.000000)">
            <g id="List" transform="translate(9.900000, 41.057143)">
              <path
                d="M14.7569375,9.28571429 C15.5155625,9.28571429 16.1296875,9.79085714 16.1296875,10.4148571 C16.1296875,11.0388571 15.5155625,11.544 14.7569375,11.544 L5.31025,11.544 C4.551625,11.544 3.9375,11.0388571 3.9375,10.4148571 C3.9375,9.79085714 4.551625,9.28571429 5.31025,9.28571429 L14.7569375,9.28571429 Z M1.3359375,0.133714286 C2.025,0.133714286 2.6015625,0.728 2.6015625,1.47085714 C2.6015625,2.21371429 2.0390625,2.808 1.3359375,2.808 C0.6328125,2.808 0.0703125,2.21371429 0.0703125,1.47085714 C0.0703125,0.728 0.6328125,0.133714286 1.3359375,0.133714286 Z M5.31025,2.6 C4.5696875,2.6 3.9555625,2.09485714 3.9375,1.47085714 C3.9375,0.846857143 4.551625,0.341714286 5.31025,0.341714286 L14.7569375,0.341714286 C15.5155625,0.341714286 16.1296875,0.846857143 16.1296875,1.47085714 C16.1296875,2.09485714 15.5155625,2.6 14.7569375,2.6 L5.31025,2.6 Z M1.3359375,4.60571429 C2.025,4.60571429 2.6015625,5.2 2.6015625,5.94285714 C2.6015625,6.68571429 2.0390625,7.28 1.3359375,7.28 C0.6328125,7.28 0.0703125,6.68571429 0.0703125,5.94285714 C0.0703125,5.2 0.6328125,4.60571429 1.3359375,4.60571429 Z M14.7569375,4.81371429 C15.5155625,4.81371429 16.1296875,5.31885714 16.1296875,5.94285714 C16.1296875,6.56685714 15.5155625,7.072 14.7569375,7.072 L5.31025,7.072 C4.551625,7.072 3.9375,6.56685714 3.9375,5.94285714 C3.9375,5.31885714 4.551625,4.81371429 5.31025,4.81371429 L14.7569375,4.81371429 Z M1.3359375,11.752 C0.636952114,11.752 0.0703125,11.1533408 0.0703125,10.4148571 C0.0703125,9.67637353 0.636952114,9.07771429 1.3359375,9.07771429 C2.03492289,9.07771429 2.6015625,9.67637353 2.6015625,10.4148571 C2.6015625,11.1533408 2.03492289,11.752 1.3359375,11.752 Z"
                id="Path-3"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "List"
};
</script>
