const props = {
  width: {
    type: [Number, String]
  },
  height: {
    type: [Number, String]
  },
  color: {
    type: String,
    default: "#251540"
  }
};

export default props;
