<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 44 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Filtres"
        transform="translate(-137.000000, -979.000000)"
        :fill="color"
        fill-rule="nonzero"
      >
        <g id="Parking-Copy" transform="translate(137.000000, 979.000000)">
          <path
            d="M36.7967742,39.2982456 L36.7967742,36.4912281 L37.4709677,36.4912281 L37.4709677,27.4736842 C37.4709677,26.3859649 36.8322581,25.4385965 35.9096774,25.0175439 L33.7806452,18.5263158 C33.3548387,17.122807 32.0419355,16.2105263 30.5870968,16.2105263 L13.4129032,16.2105263 C11.9225806,16.2105263 10.6451613,17.1578947 10.2193548,18.5263158 L8.09032258,25.0526316 C7.16774194,25.5087719 6.52903226,26.4210526 6.52903226,27.5087719 L6.52903226,36.4912281 L7.20322581,36.4912281 L7.20322581,39.2982456 L11.2483871,39.2982456 L11.2483871,36.4912281 L32.6806452,36.4912281 L32.6806452,39.2982456 L36.7967742,39.2982456 Z M35.3774194,37.8947368 L34.1709677,37.8947368 L34.1709677,36.4912281 L35.3774194,36.4912281 L35.3774194,37.8947368 Z M11.6032258,18.9473684 C11.8516129,18.1403509 12.5967742,17.6140351 13.4483871,17.6140351 L30.5870968,17.6140351 C31.4387097,17.6140351 32.183871,18.1403509 32.4322581,18.9473684 L34.3129032,24.7719298 L9.68709677,24.7719298 L11.6032258,18.9473684 Z M9.86451613,37.8947368 L8.65806452,37.8947368 L8.65806452,36.4912281 L9.86451613,36.4912281 L9.86451613,37.8947368 Z M11.283871,35.0877193 L7.98387097,35.0877193 L7.98387097,27.4736842 C7.98387097,26.7368421 8.58709677,26.1403509 9.33225806,26.1403509 L34.6677419,26.1403509 C35.4129032,26.1403509 36.016129,26.7368421 36.016129,27.4736842 L36.016129,35.0877193 L32.716129,35.0877193 L11.283871,35.0877193 Z"
            id="Shape"
          ></path>
          <path
            d="M9.72258065,32.245614 L14.2645161,32.245614 L14.2645161,28.7719298 L9.72258065,28.7719298 L9.72258065,32.245614 Z M11.1419355,30.1754386 L12.8451613,30.1754386 L12.8451613,30.8421053 L11.1419355,30.8421053 L11.1419355,30.1754386 Z"
            id="Shape"
          ></path>
          <path
            d="M29.7354839,32.245614 L34.2774194,32.245614 L34.2774194,28.7719298 L29.7354839,28.7719298 L29.7354839,32.245614 Z M31.1548387,30.1754386 L32.8580645,30.1754386 L32.8580645,30.8421053 L31.1548387,30.8421053 L31.1548387,30.1754386 Z"
            id="Shape"
          ></path>
          <path
            d="M28.316129,29.7894737 L15.683871,29.7894737 C15.2935484,29.7894737 14.9741935,30.1052632 14.9741935,30.4912281 C14.9741935,30.877193 15.2935484,31.1929825 15.683871,31.1929825 L28.316129,31.1929825 C28.7064516,31.1929825 29.0258065,30.877193 29.0258065,30.4912281 C29.0258065,30.1052632 28.7064516,29.7894737 28.316129,29.7894737 Z"
            id="Path"
          ></path>
          <path
            d="M3.01612903,39.0526316 C3.01612903,39.4385965 3.33548387,39.754386 3.72580645,39.754386 C4.11612903,39.754386 4.43548387,39.4385965 4.43548387,39.0526316 L4.43548387,10.9824561 L39.5645161,10.9824561 L39.5645161,39.0526316 C39.5645161,39.4385965 39.883871,39.754386 40.2741935,39.754386 C40.6645161,39.754386 40.983871,39.4385965 40.983871,39.0526316 L40.983871,10.9824561 L43.9645161,10.9824561 L43.9645161,8.28070175 L22.0354839,0.175438596 L0.035483871,8.31578947 L0.035483871,11.0175439 L3.01612903,11.0175439 L3.01612903,39.0526316 Z M1.45483871,9.29824561 L22.0354839,1.68421053 L42.5451613,9.26315789 L42.5451613,9.61403509 L1.45483871,9.61403509 L1.45483871,9.29824561 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "Parking"
};
</script>
