<template>
  <section class="agent" v-if="agent">
    <h2>{{ $t("your_agent") }}</h2>
    <hr />
    <div class="agent-info">
      <img :src="agent.picture_url" :alt="agent.name" />
      <div>
        <h3>{{ agent.name }}</h3>
        <p class="agent-phone">
          <Phone color="#FFFFFF" height="20" />
          <a :href="`tel:${agent.phone}`" class="agent-phone-number">
            {{ localisedPhoneNumber }}
          </a>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import Phone from "@/components/Icons/Phone.vue";

export default {
  name: "AgentCard",
  props: {
    agent: {
      type: Object,
      default: () => ({
        name: "Agent Liberkeys",
        phone: "0600000000",
        picture_url: ""
      })
    }
  },
  components: {
    Phone
  },
  computed: {
    localisedPhoneNumber() {
      // TODO: get language and add +33 if not fr-FR
      let phoneNumber = this.agent.phone.replace("+33", "0");
      phoneNumber = phoneNumber.replace(/(\S{2})/g, "$1 ");
      return phoneNumber;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.agent {
  color: white;
  background-color: $black;
  border-radius: 4px;
  overflow: auto;
  max-width: 400px;

  img {
    width: 68px;
    height: 68px;
    border-radius: 50%;
  }

  h2 {
    text-align: center;
    font-size: 18px;
    margin: 0.83em 0;
  }
  h3 {
    font-weight: bold;
    line-height: 27px;
  }
  p,
  h3 {
    font-size: 16px;
    margin: 0;
  }
  p {
    line-height: 35px;
  }
  hr {
    border-color: $grey;
  }
}

.agent-phone {
  display: flex;
  align-items: center;

  svg {
    margin-right: $space-1x;
  }
}

.agent-phone-number {
  text-decoration: none;
  color: white;
}

.agent-info {
  display: flex;
  align-items: center;
  margin: $space-2x $space-3x;
  div {
    margin-left: $space-2x;
  }
}
</style>
