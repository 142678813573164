var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{ref:"searchMap",staticClass:"map-search",attrs:{"zoom":10,"center":_vm.center,"options":{
    zoomControl: true,
    zoomControlOptions: {
      position: _vm.zoomControlPosition
    },
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: false,
    clickableIcons: false
  }},on:{"click":_vm.closeDisplayedListing}},[_vm._l((_vm.filteredMapListings),function(listing,index){return _c('gmap-custom-marker',{key:index,ref:listing.slug,refInFor:true,attrs:{"alignment":"top","marker":{
      lat: listing.lat,
      lng: listing.lng
    }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.showListingInfo(listing)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(listing !== _vm.displayedListing),expression:"listing !== displayedListing"}],class:[
        'map-listing-price',
        { 'map-listing-price-is-sold': listing.is_sold }
      ]},[_vm._v(" "+_vm._s(_vm.$n(listing.price / 1000))+"k € ")])])}),(_vm.displayedListing)?_c('gmap-custom-marker',{staticClass:"map-listing-card",attrs:{"alignment":"top","marker":{
      lat: _vm.displayedListing.lat,
      lng: _vm.displayedListing.lng
    },"delayRepaint":50},nativeOn:{"click":function($event){return _vm.preventClosing.apply(null, arguments)}}},[_c('div',{staticClass:"map-listing-card-container"},[_c('ListingCard',{staticClass:"is-map-card",attrs:{"listing":_vm.displayedListing,"openInNewTab":true,"showCarousel":false}})],1)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }