<template>
  <div
    class="page-nav"
    style="will-change: background; background-color: white;"
  >
    <div class="page-nav__container">
      <a 
        href="https://www.liberkeys.com" 
        aria-current="page" 
        class="brand w-nav-brand w--current" 
        aria-label="home">
          <img 
            src="https://assets.website-files.com/6447f3d606511b118ac3f9fd/6447f9056d72036526c47acd_LOGO-Liberkeys%20-small%20def.png" 
            loading="lazy" 
            width="150" 
            alt="" 
            class="image-10 image-logo-header"
          >
      </a>
      <div
        class="menu-button"
        aria-label="menu"
        role="button"
        tabindex="0"
        @click="toggleMenu"
      >
        <div class="menu-button__icon"></div>
      </div>
      <HeaderNav class="nav--desktop" />
    </div>
    <div v-if="isMenuOpen" class="w-nav-overlay">
      <HeaderNav class="nav--mobile" />
    </div>
  </div>
</template>

<script>
import HeaderNav from "@/components/Header/HeaderNav.vue";

export default {
  name: "Header",

  components: {
    HeaderNav
  },

  data() {
    return {
      isMenuOpen: false
    };
  },

  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";

.page-nav {
  background-color: $color-primary;
  color: white;
  position: fixed;
  width: 100%;
  z-index: 10;
  height: 80px;
  @include sliderEase(background-color);

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  p {
    margin: 0;
  }

  .logo {
    width: 150px;
    margin-top: 25px;
  }

  .image-10.image-logo-header, .nav-menu {
    margin-top: 10px;
  }

  &__container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 1rem;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
  }
}

.nav--desktop {
  display: none;

  @media screen and (min-width: 991px) {
    display: block;
  }
}

.menu-button {
  position: relative;
  font-size: 24px;
  cursor: pointer;
  box-sizing: border-box;
  color: white;
  margin-top: 15px;
  margin-bottom: auto;
  padding: 18px 5px;
  outline: none;

  @media screen and (min-width: 480px) {
    padding: 18px;
  }

  @media screen and (min-width: 991px) {
    display: none;
  }

  &__icon {
    font-family: "webflow-icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: white;

    &:before {
      content: "\e602";
    }
  }
}

.w-nav-overlay {
  position: absolute;
  overflow: hidden;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - 75px); // tophead size
}

.nav--mobile {
  background-color: #251540;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50vw;
  max-height: calc(100vh - 75px);
  padding: 0 32px;
  z-index: 1;

  width: 100vw;
  box-sizing: border-box;
}
</style>
