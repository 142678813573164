<template>
  <ul class="list-with-icons">
    <li v-for="(element, index) in elements" :key="`element-${index}`">
      <div v-if="element === 'Balcon'" class="list-icon">
        <Balcony />
      </div>
      <div v-else-if="element === 'Jardin'" class="list-icon">
        <Garden />
      </div>
      <div v-else-if="element === 'Terrasse'" class="list-icon">
        <Terrace />
      </div>
      <p>{{ element }}</p>
    </li>
  </ul>
</template>

<script>
import { Terrace, Garden, Balcony } from "@/components/Icons";

export default {
  name: "ListWithIcons",
  props: {
    elements: Array[String]
  },
  components: {
    Terrace,
    Balcony,
    Garden
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.list-with-icons {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    border: 1px solid $black;
    width: 98px; // not 100px because of the border
    height: 98px; // not 100px because of the border
    margin-right: $space-2x;
    margin-bottom: $space-2x;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  p {
    margin: 0;
    flex-basis: 100%;
    text-align: center;
  }
}

.list-icon {
  margin: 0 auto;
  > * {
    height: 40px;
  }
}
</style>
