import Vue from "vue";
import Router from "vue-router";
import Search from "@/views/Search";
import Property from "@/views/Property";
import VisitRequest from "@/views/VisitRequest";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "search",
      component: Search,
      alias: "/portails/"
    },
    {
      path: "/:slug",
      name: "property",
      component: Property,
      alias: "/portails/:slug"
    },
    {
      path: "/:slug/demande-de-visite",
      name: "visit-request",
      component: VisitRequest,
      alias: "/portails/:slug/demande-de-visite"
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});
