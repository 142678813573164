<template>
  <header :class="['search-header', { 'search-map-opened': !showSearchList }]">
    <SearchHeaderRegions />
    <section class="search-header-buttons">
      <NumberOfResults
        class="search-header-number-of-results"
        :numberOfResults="listingCount"
      />
      <div class="search-header-order-by">
        <Dropdown
          :dropdownName="$t('order_by')"
          :value="this.value"
          :options="orderByOptions"
          @change="
            selectedValue =>
              filterChanged({ name: 'order', value: selectedValue })
          "
        />
      </div>
      <MapListSwitch class="search-header-switch" />
    </section>
  </header>
</template>

<script>
import SearchHeaderRegions from "@/components/Search/SearchHeaderRegions.vue";
import NumberOfResults from "@/components/NumberOfResults.vue";
import MapListSwitch from "@/components/MapListSwitch.vue";
import Dropdown from "@/components/Dropdown.vue";

import { mapState, mapActions } from "vuex";

export default {
  name: "SearchHeader",
  components: {
    NumberOfResults,
    SearchHeaderRegions,
    Dropdown,
    MapListSwitch
  },
  data() {
    return {
      orderByOptions: [
        { value: "recent_asc", name: this.$t("recent_asc") },
        { value: "recent_desc", name: this.$t("recent_desc") },
        { value: "price_asc", name: this.$t("price_asc") },
        { value: "price_desc", name: this.$t("price_desc") }
      ]
    };
  },
  computed: {
    ...mapState(["listingCount", "showSearchList", "filterParameters"]),
    value() {
      return this.orderByOptions.find(
        order => order.value === this.filterParameters.order
      );
    }
  },
  methods: {
    ...mapActions(["getListings", "updateFilters"]),
    filterChanged(parameters) {
      this.updateFilters(parameters);
      this.getListings();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.search-header {
  z-index: 2;
  position: relative;
}

.search-header-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $space-2x;
  @media #{$desktop-and-up} {
    margin: $space-2x $space-4x;
  }
}

.search-header-order-by {
  flex-grow: 1;
  @media #{$tablet-landscape-and-up} {
    flex-grow: 0;
  }
}

.search-header-number-of-results {
  margin-right: $space-4x;
}

.search-map-opened {
  .search-header-order-by,
  .search-header-number-of-results {
    display: none;
    @media #{$tablet-landscape-and-up} {
      display: block;
    }
  }
  .search-header-buttons {
    flex-direction: row-reverse;
    @media #{$tablet-landscape-and-up} {
      flex-direction: row;
    }
  }
}
</style>
