<template>
  <svg viewBox="0 0 448 448" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m304 280c-4.109375 0-7.550781-3.113281-7.960938-7.199219l-8-80c-.039062-32.335937-34.4375-56.800781-64.039062-56.800781s-64 24.464844-64 56l-8 80.800781c-.414062 4.101563-3.875 7.21875-8 7.199219-10.167969.011719-19.230469 6.417969-22.632812 16h205.265624c-3.402343-9.582031-12.464843-15.988281-22.632812-16zm0 0"
    />
    <path
      d="m224 0c-123.710938 0-224 100.289062-224 224s100.289062 224 224 224 224-100.289062 224-224c-.140625-123.652344-100.347656-223.859375-224-224zm112 312h-76c-1.1875 18.738281-17.253906 33.015625-36 32-18.746094 1.015625-34.8125-13.261719-36-32h-76c-4.417969 0-8-3.582031-8-8 .027344-19.265625 13.757812-35.785156 32.695312-39.335938l7.34375-73.464843c0-32.910157 28-59.4375 58.785157-68.144531-1.828125-3.398438-2.796875-7.195313-2.824219-11.054688 0-13.253906 10.746094-24 24-24s24 10.746094 24 24c-.027344 3.859375-.996094 7.65625-2.824219 11.054688 30.824219 8.738281 58.824219 35.34375 58.824219 68.945312l7.304688 72.664062c18.9375 3.550782 32.667968 20.070313 32.695312 39.335938 0 4.417969-3.582031 8-8 8zm0 0"
    />
    <path
      d="M224 328c9.894531.941406 18.75-6.140625 20-16h-40c1.25 9.859375 10.105469 16.941406 20 16zm0 0M232 112c0 4.417969-3.582031 8-8 8s-8-3.582031-8-8 3.582031-8 8-8 8 3.582031 8 8zm0 0"
    />
  </svg>
</template>

<script>
export default {
  name: "Alert"
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

svg {
  fill: $pink;
}
</style>
