<template>
  <div>
    <div
      class="collapsible"
      :style="{ maxHeight: `${isOpen ? fullHeight : collapsedHeight}px` }"
    >
      <div ref="content">
        <slot></slot>
      </div>
    </div>
    <a
      class="collaspible-read-more"
      v-if="shouldCollapse"
      @click.prevent="collapseOrExtend"
      href=""
    >
      {{ readMoreOrLess }}
    </a>
  </div>
</template>

<script>
export default {
  name: "Collapsible",
  props: {
    collapsedHeight: Number,
    showMoreText: {
      type: String,
      default() {
        return this.$t("read_more");
      }
    },
    showLessText: {
      type: String,
      default() {
        return this.$t("read_less");
      }
    }
  },
  data() {
    return {
      isOpen: false,
      shouldCollapse: false,
      fullHeight: undefined
    };
  },
  computed: {
    readMoreOrLess() {
      return this.isOpen ? this.showLessText : this.showMoreText;
    }
  },
  mounted() {
    this.checkShouldCollapse();
    // TODO debounce
    window.addEventListener("resize", this.checkShouldCollapse);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkShouldCollapse);
  },
  methods: {
    collapseOrExtend() {
      this.isOpen = !this.isOpen;
    },
    checkShouldCollapse() {
      this.fullHeight = this.$refs.content.getBoundingClientRect().height;
      this.shouldCollapse = this.fullHeight > this.collapsedHeight;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.collapsible {
  transition: max-height, 500ms;
  overflow: hidden;
}
.collaspible-read-more {
  margin-top: $space-2x;
  color: $pink;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
}

.property-description {
  > .collapsible > div {
    white-space: pre-wrap;
  }
}
</style>
