import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";

import {
  fetchListings,
  fetchMapListings,
  fetchListing,
  fetchLocations,
  postVisitRequestMessage
} from "@/services/listings";
import { DEFAULT_FILTERS } from "@/constants";

Vue.use(Vuex);

const state = {
  isSearchFiltersModalOpen: false,
  showSearchList: true,
  listings: [],
  mapListings: [],
  listingCount: 0,
  filterParameters: JSON.parse(JSON.stringify(DEFAULT_FILTERS)),
  activeListingSlug: "",
  displayedListing: undefined,
  propertyGallery: {
    isOpen: false,
    show3DView: false
  },
  autocompleteZipCodes: [],
  messageSentSucces: [],
  messageSentFail: [],
  highlightListing: "",
  isFetchingListings: false
};

export default new Vuex.Store({
  state,
  mutations: {
    toggleFilterModal() {
      state.isSearchFiltersModalOpen = !state.isSearchFiltersModalOpen;
    },
    switchMapOrList() {
      state.showSearchList = !state.showSearchList;
    },
    /* eslint-disable no-empty-pattern */
    isFetchingListings() {
      state.isFetchingListings = true;
    },
    setListings({}, { listings }) {
      state.listings = listings || [];
      state.isFetchingListings = false;
    },
    setMapListings({}, { mapListings }) {
      state.mapListings = mapListings || [];
    },
    setListingCount({}, { count }) {
      state.listingCount = count;
    },
    setListing({}, { listing }) {
      state.displayedListing = listing;
    },
    setFilter({}, filter) {
      if (typeof filter.target !== "undefined") {
        const filterIndex = state.filterParameters[filter.name].indexOf(
          filter.target
        );
        if (filterIndex !== -1) {
          state.filterParameters[filter.name].splice(filterIndex, 1);
        } else {
          state.filterParameters[filter.name].push(filter.target);
        }
      } else {
        state.filterParameters[filter.name] = filter.value;
      }
      if (filter.name === "region") {
        state.filterParameters.zip_codes = [];
      }
    },
    resetFilters({}) {
      const newfilterParameters = JSON.parse(JSON.stringify(DEFAULT_FILTERS));
      newfilterParameters.region = state.filterParameters.region;
      state.filterParameters = newfilterParameters;
    },
    setFilters({}, parameters) {
      const newfilterParameters = Object.assign(
        {},
        state.filterParameters,
        parameters
      );
      state.filterParameters = newfilterParameters;
    },
    setAutocompleteValues({}, { zipCodes }) {
      state.autocompleteZipCodes = zipCodes;
    },
    addActiveZipCode({}, { zipCode }) {
      const zipCodesList = zipCode.value.split(",");
      zipCodesList.forEach(zipcode => {
        zipcode = zipcode.trim();
        const zipCodeIndex = state.filterParameters.zip_codes.indexOf(zipcode);
        if (zipCodeIndex === -1) {
          state.filterParameters.zip_codes.push(zipcode);
        }
      });
    },
    removeActiveZipCode({}, { zipCode }) {
      const zipCodeToRemoveIndex = state.filterParameters.zip_codes.indexOf(
        zipCode
      );
      if (zipCodeToRemoveIndex !== -1) {
        state.filterParameters.zip_codes.splice(zipCodeToRemoveIndex, 1);
      }
    },
    removeActiveRegion() {
      state.filterParameters.region = undefined;
    },
    setSuccessMessage({}, { slug }) {
      state.messageSentSucces.push(slug);
      const errorMessageIndex = state.messageSentFail.findIndex(
        error => error.slug === slug
      );
      state.messageSentFail.splice(errorMessageIndex, 1);
    },
    setFailMessage({}, error) {
      state.messageSentFail.push(error);
    },
    openPropertyGallery({}, { show3DView }) {
      state.propertyGallery = {
        show3DView,
        isOpen: true
      };
    },
    closePropertyGallery() {
      state.propertyGallery = {
        show3DView: false,
        isOpen: false
      };
    },
    removeHighlightListing() {
      state.highlightListing = "";
    },
    setHighlightListing({}, { listing }) {
      state.highlightListing = listing;
    }
  },
  actions: {
    openFilters({ commit }) {
      commit("toggleFilterModal", null);
    },
    closeFilters({ commit }) {
      commit("toggleFilterModal", null);
    },
    switchView({ commit }) {
      commit("switchMapOrList", null);
    },
    getListings({ commit }) {
      router.push({ name: "search", query: state.filterParameters });
      commit("isFetchingListings");
      fetchListings(state.filterParameters).then(response => {
        commit("setListings", {
          listings: response.data.properties
        });
        commit("setListingCount", {
          count: response.data.count
        });
      });
    },
    getMapListings({ commit }) {
      fetchMapListings(state.filterParameters).then(response => {
        commit("setMapListings", {
          mapListings: response.data.properties
        });
      });
    },
    setActiveListing({ commit }, listing) {
      commit("setListing", {
        listing
      });
    },
    getListingDetails({ commit }, slug) {
      return fetchListing(slug).then(response => {
        commit("setListing", {
          listing: response.data
        });
      });
    },
    updateFilters({ commit }, parameters) {
      commit("setFilter", parameters);
    },
    resetFilters({ commit }) {
      commit("resetFilters");
    },
    setFilters({ commit }, parameters) {
      commit("setFilters", parameters);
    },
    selectZipCode({ commit }, zipCode) {
      commit("addActiveZipCode", {
        zipCode
      });
    },
    removeZipCode({ commit }, zipCode) {
      commit("removeActiveZipCode", {
        zipCode
      });
    },
    getAutocompleteValues({ commit }, query) {
      if (query.length >= 2) {
        fetchLocations(query).then(response => {
          commit("setAutocompleteValues", {
            zipCodes: response.data.zip_codes
          });
        });
      }
    },
    removeRegion({ commit }) {
      commit("removeActiveRegion");
    },
    submitVisitRequest({ commit }, { slug, params }) {
      postVisitRequestMessage(slug, params).then(response => {
        if (response.status < 300) {
          commit("setSuccessMessage", {
            slug
          });
        } else {
          commit("setFailMessage", {
            slug,
            message: response.statusText
          });
        }
      });
    },
    closePropertyGallery({ commit }) {
      commit("closePropertyGallery");
    },
    openPropertyGallery({ commit }, show3DView = false) {
      commit("openPropertyGallery", {
        show3DView
      });
    },
    setHighlightListing({ commit }, listing) {
      commit("setHighlightListing", {
        listing
      });
    },
    removeHighlightListing({ commit }) {
      commit("removeHighlightListing");
    }
  }
});
