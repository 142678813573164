<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 44 44"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 56.2 (81672) - https://sketch.com -->
    <title>Group</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Board" transform="translate(-829.000000, -684.000000)">
        <g id="Group" transform="translate(829.000000, 684.000000)">
          <polyline
            id="Rectangle"
            :stroke="color"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            transform="translate(22.485281, 24.000000) rotate(-315.000000) translate(-22.485281, -24.000000) "
            points="28.4852814 18 28.4852814 30 16.4852814 30"
          ></polyline>
          <rect
            id="Rectangle"
            :fill="color"
            x="21.4852814"
            y="11"
            width="2"
            height="21"
            rx="1"
          ></rect>
          <circle
            id="Oval"
            :stroke="color"
            stroke-width="2"
            cx="22"
            cy="22"
            r="21"
          ></circle>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props }
};
</script>

<style lang="scss" scoped></style>
