<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 21 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Annonce"
        transform="translate(-547.000000, -523.000000)"
        :fill="color"
        fill-rule="nonzero"
      >
        <g id="Pièces" transform="translate(165.000000, 521.000000)">
          <g
            id="Cave"
            transform="translate(392.500000, 10.000000) scale(-1, 1) translate(-392.500000, -10.000000) translate(382.000000, 2.000000)"
          >
            <path
              d="M13.7796247,3.55555556 L13.7006574,3.55555556 L13.7006574,8.72397503 L7.10688124,8.72397503 L7.10688124,8.73097289 L7.09496604,8.73097289 L7.09496604,13.900642 L0.875,13.900642 L0.875,16 C3.60241177,16 6.32982354,16 9.05723531,16 L9.05723531,10.823333 C11.2599112,10.823333 13.460718,10.8245827 15.662693,10.8245827 L15.662693,5.65491356 L21,5.65491356 L21,3.55555556 L13.7796247,3.55555556 Z"
              id="Path"
            ></path>
            <path
              d="M4.81396314,7.11111111 C5.31953293,7.11111111 5.72918345,6.69410312 5.72918345,6.1794531 C5.72918345,5.66480308 5.31953293,5.24779509 4.81396314,5.24779509 L3.1389072,5.24779509 L6.73189308,1.59050976 C7.08936897,1.22661347 7.08936897,0.636818517 6.73189308,0.272922221 C6.37464502,-0.0909740738 5.79525611,-0.0909740738 5.43755238,0.272922221 L1.83044062,3.94481906 L1.83044062,2.26008822 C1.83044062,1.74567013 1.42056227,1.32843021 0.91522031,1.32843021 C0.409650515,1.32843021 0,1.74567013 0,2.26008822 L0,6.01803065 C0,6.10082924 0.0104804915,6.1810766 0.0305301273,6.25761311 C0.0694902152,6.73538645 0.462964319,7.11087918 0.942560723,7.11087918 L4.81396314,7.11111111 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "Stairs"
};
</script>
