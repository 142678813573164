<template>
  <div class="diagnostic-scale">
    <ul>
      <li
        v-for="range in valueRange"
        :key="'range-' + range.value"
        :class="{ active: range.value === value }"
        :style="{ backgroundColor: range.color }"
      >
        {{ range.value }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DiagnosticScale",
  props: {
    value: {
      type: String
    },
    valueRange: {
      type: Array
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
$border-radius: 3px;

.diagnostic-scale {
  ul {
    display: flex;
    list-style: none;
    padding: 0;
  }
  li {
    width: $space-4x;
    line-height: $space-4x;
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: 700;
    &.active {
      transform: scale(1.21, 1.21);
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 1);
      font-size: 25px;
      border-radius: 2px;
    }
    &:first-of-type {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    &:last-of-type {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}
</style>
