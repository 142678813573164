<template>
  <div class="filters">
    <form class="filters-form" @submit.prevent="">
      <SearchHeaderRegions :display-input="true" />
      <div class="filters-container">
        <section class="display-sold-properties">
          <input
            type="checkbox"
            id="include_sold_properties"
            name="include_sold_properties"
            :checked="filterParameters.include_sold_properties"
            @change="
              event =>
                filterChanged({
                  name: 'include_sold_properties',
                  value: event.target.checked
                })
            "
          />
          <label for="include_sold_properties">
            <div class="custom-checkbox">
              <Check
                color="#ed9473"
                :width="10"
                v-if="filterParameters.include_sold_properties"
              />
            </div>
            <p>{{ $t("show_sold_properties") }}</p>
          </label>
        </section>
        <hr />
        <section class="types">
          <div class="checkbox-with-icon">
            <input
              type="checkbox"
              id="types_flat"
              name="types"
              :checked="filterParameters.types.indexOf('flat') !== -1"
              @change="
                event =>
                  filterChanged({
                    name: 'types',
                    value: event.target.checked,
                    target: 'flat'
                  })
              "
            />
            <label for="types_flat">
              <Flat
                :height="40"
                :color="
                  filterParameters.types.indexOf('flat') !== -1
                    ? '#251540'
                    : '#979797'
                "
              />
              <p>{{ $t("flat") }}</p>
            </label>
          </div>
          <div class="checkbox-with-icon">
            <input
              type="checkbox"
              id="types_house"
              name="types"
              :checked="filterParameters.types.indexOf('house') !== -1"
              @change="
                event =>
                  filterChanged({
                    name: 'types',
                    value: event.target.checked,
                    target: 'house'
                  })
              "
            />
            <label for="types_house">
              <House
                :height="40"
                :color="
                  filterParameters.types.indexOf('house') !== -1
                    ? '#251540'
                    : '#979797'
                "
              />
              <p>{{ $t("house") }}</p>
            </label>
          </div>
        </section>
        <hr />
        <section>
          <h3>{{ $t("budget") }}</h3>
          <div class="budget-slider">
            <div class="budget-slider-info">
              <span>-</span>
              {{ $n(filterParameters.min_price) }} {{ $t("to") }}
              {{ $n(filterParameters.max_price) }} €
              <span>+</span>
            </div>
            <vue-slider
              :dotSize="23"
              :dotStyle="{
                backgroundColor: '#ed9473',
                boxShadow: 'none',
                border: '3px solid white'
              }"
              :processStyle="{
                height: '3px',
                backgroundColor: '#ed9473'
              }"
              :railStyle="{
                height: '3px',
                backgroundColor: '#D6D6D6'
              }"
              :tooltip="'none'"
              v-model="sliderValue"
              :enable-cross="false"
              :min="0"
              :max="maxPrice"
              :interval="10000"
              @change="
                sliderModel => {
                  filterChanged({ name: 'min_price', value: sliderModel[0] });
                  filterChanged({ name: 'max_price', value: sliderModel[1] });
                }
              "
            />
          </div>
        </section>
        <hr />
        <section class="surface-and-rooms">
          <div class="surface">
            <h3>{{ $t("surface_of") }}</h3>
            <div class="surface-inputs">
              <div class="surface-input">
                <input
                  type="number"
                  min="0"
                  :max="filterParameters.max_surface"
                  v-model.number="filterParameters.min_surface"
                  placeholder="min"
                  @change="
                    event =>
                      filterChanged({
                        name: 'min_surface',
                        value: event.target.value
                      })
                  "
                  name="min_surface"
                />
                <span>{{ $t("square_meters") }}</span>
              </div>
              <p>{{ $t("to") }}</p>
              <div class="surface-input">
                <input
                  type="number"
                  :min="
                    filterParameters.min_surface
                      ? filterParameters.min_surface
                      : 0
                  "
                  v-model.number="filterParameters.max_surface"
                  placeholder="max"
                  @change="
                    event =>
                      filterChanged({
                        name: 'max_surface',
                        value: event.target.value
                      })
                  "
                  name="max_surface"
                />
                <span>{{ $t("square_meters") }}</span>
              </div>
            </div>
            <div class="input-warning" v-if="isMinOverMax">
              {{ $t("min_over_max_warning") }}
            </div>
          </div>
          <NumberOfRoomsSelector
            :title="$t('number_of_rooms')"
            name="room_count"
            :selected="filterParameters.room_count"
            @change="
              selectedValue =>
                filterChanged({ name: 'room_count', value: selectedValue })
            "
          />

          <NumberOfRoomsSelector
            :title="$t('number_of_bedrooms')"
            name="bedroom_count"
            :selected.sync="filterParameters.bedroom_count"
            @change="
              selectedValue =>
                filterChanged({ name: 'bedroom_count', value: selectedValue })
            "
          />
        </section>
        <hr />
        <section class="services">
          <h3>{{ $t("services") }}</h3>
          <div class="services-container">
            <div
              v-for="criteria in criterias"
              :key="criteria"
              class="checkbox-with-icon"
            >
              <input
                type="checkbox"
                :id="`criteria_${criteria}`"
                name="criteria"
                :checked="filterParameters.criteria.indexOf(criteria) !== -1"
                @change="
                  event =>
                    filterChanged({
                      name: 'criteria',
                      value: event.target.checked,
                      target: criteria
                    })
                "
              />
              <label :for="`criteria_${criteria}`">
                <component
                  :is="criteria"
                  :height="40"
                  :color="
                    filterParameters.criteria.indexOf(criteria) !== -1
                      ? '#251540'
                      : '#979797'
                  "
                />
                <p>{{ $t(criteria) }}</p>
              </label>
            </div>
          </div>
        </section>
      </div>
      <SearchFiltersFooter @isMinOverMax="bool => (isMinOverMax = bool)" />
    </form>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import SearchHeaderRegions from "@/components/Search/SearchHeaderRegions.vue";
import SearchFiltersFooter from "@/components/Search/SearchFiltersFooter.vue";
import NumberOfRoomsSelector from "@/components/NumberOfRoomsSelector.vue";
import { MAX_PRICE } from "@/constants";

import {
  // eslint-disable-next-line camelcase
  Flat,
  House,
  Cellar,
  Parking,
  Exterior,
  Caretaker,
  Elevator,
  High_floor,
  Check
} from "@/components/Icons";

import "vue-slider-component/theme/default.css";
import { mapActions, mapState } from "vuex";

export default {
  name: "FiltersButton",
  // this is for the slider only, break down
  components: {
    VueSlider,
    SearchHeaderRegions,
    NumberOfRoomsSelector,
    SearchFiltersFooter,
    Flat,
    House,
    Cellar,
    Parking,
    Exterior,
    Caretaker,
    Elevator,
    High_floor,
    Check
  },
  data() {
    return {
      maxPrice: MAX_PRICE,
      criterias: [
        "cellar",
        "parking",
        "exterior",
        "caretaker",
        "elevator",
        "high_floor"
      ],
      isMinOverMax: false
    };
  },
  computed: {
    ...mapState(["filterParameters"]),
    sliderValue: {
      get() {
        return [
          this.filterParameters.min_price,
          this.filterParameters.max_price
        ];
      },
      set() {}
    }
  },
  methods: {
    ...mapActions(["updateFilters"]),
    filterChanged(parameters) {
      this.updateFilters(parameters);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.filters {
  position: fixed;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  @media #{$tablet-landscape-and-up} {
    width: 66.66667%;
    top: 50px;
    z-index: 5;
  }

  input[type="checkbox"] {
    display: none;
  }
}

.filters-container {
  margin: $space-4x;
}

.types {
  display: flex;
  .checkbox-with-icon {
    flex-basis: 50%;
    @media #{$tablet-landscape-and-up} {
      flex-basis: auto;
    }
    label {
      @media #{$tablet-landscape-and-up} {
        display: flex;
        justify-content: flex-start;
      }
      svg {
        @media #{$tablet-landscape-and-up} {
          margin-right: $space-2x;
        }
      }
    }
  }
}

.filters {
  h3 {
    font-size: 14px;
    line-height: 24px;
  }
}
.filters hr {
  border-color: $grey;
  border-style: dashed;
  border-bottom: none;
  margin: $space-3x 0;
}

.custom-checkbox {
  border: 1px solid $pink;
  display: inline-block;
  border-radius: 3px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: relative;
  margin-right: $space-2x;
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 1px;
  }
}

.display-sold-properties {
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  p {
    margin: 0;
  }
}

.checkbox-with-icon {
  label {
    border: 1px solid #d6d6d6;
    border-radius: 3px;
    display: block;
    padding: $space-2x;
    margin-right: $space-1x;
    margin-bottom: $space-1x;
    min-width: 90px;
    cursor: pointer;
    transition: opacity 200ms;
    color: #979797;
  }

  p {
    line-height: 29px;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    text-align: center;
    margin-top: $space-1x;
  }

  svg {
    display: block;
    margin: 0 auto;
  }
  input:checked + label {
    opacity: 1;
    border-color: $black;
    color: $black;
  }
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: $space-8x;
  @media #{$tablet-portrait-and-up} {
    justify-content: flex-start;
  }
  @media #{$tablet-landscape-and-up} {
    margin-bottom: $space-12x;
  }
}

.budget-slider {
  border: 1px solid $grey;
  border-radius: 2px;
  padding: $space-2x;
}

.budget-slider-info {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;

  span {
    font-size: 26px;
  }
}

.surface-and-rooms {
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-right: $space-10x;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.surface-input {
  position: relative;

  input {
    width: 84px;
    padding: $space-1x $space-4x $space-1x $space-1x;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $space-1x;
  }
}

.input-warning {
  margin-top: $space-1x;
  color: red;
  font-size: 0.9em;
  max-width: 277px;
}

.surface-inputs {
  display: flex;
  align-items: center;
}

.surface {
  p {
    margin: 0 $space-1x;
  }
}
</style>
