<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 7 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Annonce"
        transform="translate(-786.000000, -523.000000)"
        :fill="color"
      >
        <g id="Pièces" transform="translate(165.000000, 521.000000)">
          <path
            d="M621,19 L628,19 L628,2 L621,2 L621,19 Z M622.898235,7.16752336 L626.102594,7.16752336 L626.102594,4.09719626 L622.898235,4.09719626 L622.898235,7.16752336 Z M622.898235,12.0355607 L626.102594,12.0355607 L626.102594,8.96523364 L622.898235,8.96523364 L622.898235,12.0355607 Z M622.898235,16.9035981 L626.102594,16.9035981 L626.102594,13.833271 L622.898235,13.833271 L622.898235,16.9035981 Z"
            id="Etages"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "Floor"
};
</script>
