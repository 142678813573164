<template>
  <div class="property-details-layout">
    <header class="property-details-header">
      <a @click.prevent="$router.go(-1)" href="" class="back-arrow">
        <Arrow direction="left" :width="30" />
      </a>
      <h2 class="hidden-large" v-if="title">{{ title }}</h2>
    </header>
    <div class="property-details-container">
      <ListingCard
        class="hidden-large mini"
        :listing="displayedListing"
        :show-carousel="false"
        :light-card="true"
        v-if="displayedListing"
      />
      <div
        class="property-details-content"
        :style="{ 'flex-basis': `${contentWidth}px` }"
      >
        <slot></slot>
      </div>
      <aside class="property-info-reminder">
        <ListingCard
          class="visit-request-listing hidden-small"
          :listing="displayedListing"
          :show-carousel="false"
          :light-card="true"
          v-if="displayedListing"
        />
        <AgentCard
          class="visit-request-agent"
          v-if="displayedListing"
          :agent="displayedListing.agent"
        />
      </aside>
    </div>
  </div>
</template>

<script>
import AgentCard from "@/components/AgentCard.vue";
import ListingCard from "@/components/ListingCard.vue";
import { Arrow } from "@/components/Icons";

import { mapState, mapActions } from "vuex";

export default {
  name: "PropertyDetailsLayout",
  props: {
    title: String,
    contentWidth: {
      type: Number,
      default: 600
    }
  },
  components: {
    AgentCard,
    ListingCard,
    Arrow
  },
  computed: {
    ...mapState(["displayedListing"]),
    slug() {
      return this.$route.params.slug;
    }
  },
  methods: {
    ...mapActions(["getListingDetails"])
  },
  created() {
    if (typeof this.displayedListing === "undefined") {
      this.getListingDetails(this.slug);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.property-info-reminder {
  width: 100%;
  max-width: 400px;
  @media #{$tablet-landscape-and-up} {
    max-width: 290px;
    margin-left: $space-2x;
    margin-right: $space-2x;
    margin-top: $space-2x;
  }
}
.property-details-layout {
  background-color: $light-grey;
}
.back-arrow {
  position: absolute;
  margin-top: $space-2x;
  @media #{$tablet-landscape-and-up} {
    top: 0;
    transform: none;
    margin-top: $space-2x;
    margin-left: $space-2x;
  }

  svg {
    height: 18px;
    @media #{$tablet-landscape-and-up} {
      height: auto;
    }
  }
}
.property-details-header {
  position: relative;
  h2 {
    text-align: center;
    margin: 0;
    line-height: 28px;
    padding: $space-2x 0;
    text-transform: uppercase;
  }
}

.hidden-small {
  display: none;
  @media #{$tablet-landscape-and-up} {
    display: block;
  }
}
.hidden-large {
  display: block;
  @media #{$tablet-landscape-and-up} {
    display: none;
  }
}

.property-details-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 $space-2x;
  @media #{$tablet-landscape-and-up} {
    margin: 0;
    flex-wrap: nowrap;
    min-height: 100vh;
    align-items: flex-start;
  }

  h2 {
    text-align: center;
    text-transform: uppercase;
    margin: 0.83em 0;
  }

  label {
    color: $black;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
  }
}

.listing-card {
  margin-bottom: $space-2x;
  border-radius: 4px;
  overflow: hidden;
  margin-top: $space-2x;
  @media #{$tablet-landscape-and-up} {
    margin-top: 0;
  }
}

.visit-request-agent {
  margin-bottom: $space-4x;
}

.back-to-listing-link {
  color: $pink;
  font-size: 14px;
  font-weight: 600;
}

.property-details-content {
  background-color: white;
  border-radius: 4px;
  padding: $space-2x;
  width: 100%;
  margin-bottom: $space-2x;
  @media #{$tablet-portrait-and-up} {
    padding: $space-4x;
  }
  @media #{$tablet-landscape-and-up} {
    margin-top: $space-2x;
    margin-left: $space-2x;
    flex-basis: 600px;
    width: auto;
  }
}

.no-content-padding .property-details-content {
  padding: 0;
}
</style>
