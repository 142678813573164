<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 15 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Annonce"
        transform="translate(-651.000000, -525.000000)"
        :fill="color"
        fill-rule="nonzero"
      >
        <g id="Pièces" transform="translate(165.000000, 521.000000)">
          <g id="Parking" transform="translate(486.000000, 4.000000)">
            <path
              d="M12.0887633,0 L2.91100654,0 C1.31399601,0 0,1.31401617 0,2.91105121 L0,12.0889488 C0,13.6859838 1.31399601,15 2.91100654,15 L12.0887633,15 C13.6857738,15 14.9997698,13.6859838 14.9997698,12.0889488 L14.9997698,2.93126685 C15.0199852,1.31401617 13.7059891,0 12.0887633,0 Z M11.2801504,7.09568733 C11.1386431,7.47978437 10.9162745,7.82345013 10.63326,8.106469 C10.3502455,8.4097035 10.006585,8.63207547 9.58206321,8.81401617 C9.17775674,8.99595687 8.69258899,9.07681941 8.1872059,9.07681941 L6.28696552,9.07681941 L6.28696552,12.2708895 L4.20478723,12.2708895 L4.20478723,2.76954178 L8.20742123,2.76954178 C8.73301963,2.76954178 9.21818739,2.87061995 9.62249385,3.05256065 C10.0268003,3.23450135 10.3906761,3.47708895 10.6534753,3.78032345 C10.9364899,4.08355795 11.1386431,4.42722372 11.2801504,4.79110512 C11.4216576,5.15498652 11.5025189,5.53908356 11.5025189,5.92318059 C11.5025189,6.32749326 11.4216576,6.7115903 11.2801504,7.09568733 Z"
              id="Shape"
            ></path>
            <path
              d="M7.79220779,4.41176471 L6.17647059,4.41176471 L6.17647059,7.05882353 L7.79220779,7.05882353 C7.92971734,7.05882353 8.06722689,7.0123839 8.18754775,6.94272446 C8.3078686,6.87306502 8.42818946,6.78018576 8.51413293,6.64086687 C8.60007639,6.5247678 8.68601986,6.3622291 8.73758594,6.1996904 C8.78915202,6.0371517 8.82352941,5.85139319 8.82352941,5.66563467 C8.82352941,5.29411765 8.73758594,4.99226006 8.53132162,4.73684211 C8.35943468,4.52786378 8.10160428,4.41176471 7.79220779,4.41176471 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "ParkingSpace"
};
</script>
