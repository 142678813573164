<!-- eslint-disable max-len -->
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 15 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Annonce"
        transform="translate(-314.000000, -523.000000)"
        :fill="color"
        fill-rule="nonzero"
      >
        <g id="Pièces" transform="translate(165.000000, 521.000000)">
          <g
            id="Shower"
            transform="translate(156.500000, 9.500000) scale(-1, 1) translate(-156.500000, -9.500000) translate(149.000000, 2.000000)"
          >
            <path
              d="M4.0625,9.71157686 L5.9375,9.71157686 C5.9375,14.3345277 13.1125,14.3345277 13.1125,9.71157686 L13.1125,0.195183421 L14.975,0.195183421 L14.9875,9.71157686 C15,16.7689539 4.075,16.7566588 4.0625,9.71157686 Z M1.2375,4.28944572 C0.3625,2.83862604 0,2.17469162 0,1.52305227 C0,0.62551129 0.6875,0.195183421 1.2375,0.195183421 C1.7625,0.195183421 2.4375,0.62551129 2.4375,1.52305227 C2.4375,2.17469162 2.1,2.81403588 1.2375,4.28944572 Z M5,4.28944572 C4.1375,2.83862604 3.7625,2.17469162 3.7625,1.52305227 C3.7625,0.62551129 4.4625,0.207478503 5,0.207478503 C5.5375,0.207478503 6.2375,0.62551129 6.2375,1.52305227 C6.2375,2.17469162 5.8875,2.81403588 5,4.28944572 Z M8.7625,4.28944572 C7.9,2.85092113 7.5625,2.19928178 7.5625,1.53534736 C7.5625,0.62551129 8.2375,0.207478503 8.7625,0.207478503 C9.3125,0.207478503 10,0.62551129 10,1.53534736 C10,2.19928178 9.65,2.83862604 8.7625,4.28944572 Z M1.2625,5.85092113 L8.7375,5.85092113 C9.375,5.85092113 9.375,5.85092113 9.375,6.47797031 L9.375,6.71157686 C9.375,11.67879 0.625,11.67879 0.625,6.71157686 L0.625,6.47797031 C0.625,5.85092113 0.625,5.85092113 1.2625,5.85092113 Z"
              id="Shape"
              transform="translate(7.493755, 7.597592) scale(-1, 1) rotate(-180.000000) translate(-7.493755, -7.597592) "
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
import props from "@/components/Icons/props";

export default {
  props: { ...props },
  name: "Shower"
};
</script>
