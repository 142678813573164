<template>
  <div class="property-price-detail">
    <Price :is-sold="isSold" :price="price" :display-is-sold="displayIsSold" />
    <p class="property-square-meter-price">
      {{ $n(this.surface, "surface", "fr-FR") }} m² soit {{ $n(pricePerSquareMeter, "currency", "fr-FR") }} / m²
    </p>
  </div>
</template>

<script>
import Price from "@/components/Price.vue";

export default {
  name: "PropertyPriceDetails",
  props: {
    isSold: Boolean,
    displayIsSold: {
      type: Boolean,
      default: true
    },
    price: Number,
    surface: Number
  },
  components: {
    Price
  },
  computed: {
    pricePerSquareMeter() {
      return Math.round(this.price / this.surface);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.property-price-detail {
  @media #{$tablet-landscape-and_up} {
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
  }
}
.property-price-detail > * {
  display: inline-block;
  margin-top: 0.83em;
}
.property-square-meter-price {
  margin-left: $space-2x;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: $space-2x;
}
</style>
