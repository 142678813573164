<template>
  <div class="search-page">
    <Filters v-if="isSearchFiltersModalOpen" />
    <div class="search-page-content">
      <SearchHeader />
      <div :class="{ 'hide-view': !showSearchList }">
        <SearchList />
        <SearchPager v-if="listingCount > maxResults" />
      </div>
    </div>
    <div class="search-page-map">
      <SearchMap
        :class="{ 'hide-view': showSearchList }"
        :showMap="!showSearchList"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SearchHeader from "@/components/Search/SearchHeader.vue";
import SearchList from "@/components/Search/SearchList.vue";
import SearchMap from "@/components/Search/SearchMap.vue";
import SearchPager from "@/components/Search/SearchPager.vue";
import Filters from "@/components/Filters.vue";
import { MAX_RESULTS_PER_PAGE } from "@/constants";
import { mapState, mapActions } from "vuex";

export default {
  name: "search",
  components: {
    SearchHeader,
    SearchList,
    SearchMap,
    SearchPager,
    Filters
  },
  data() {
    return {
      maxResults: MAX_RESULTS_PER_PAGE
    };
  },
  computed: mapState([
    "isSearchFiltersModalOpen",
    "showSearchList",
    "listingCount"
  ]),
  methods: mapActions(["getListings", "getMapListings", "setFilters"]),
  mounted() {
    const queryParam = this.$router.currentRoute.query;
    const keys = Object.keys(queryParam);
    keys.forEach(key => {
      const value = queryParam[key];
      if (
        (key === "types" || key === "criteria" || key === "zip_codes") &&
        typeof value === "string"
      ) {
        queryParam[key] = [value];
        // eslint-disable-next-line no-restricted-globals
      } else if (!isNaN(value)) {
        queryParam[key] = parseInt(value, 0);
      } else if (value === "true" || value === "false") {
        queryParam[key] = value === "true";
      }
    });
    this.setFilters(queryParam);
    this.getListings();
    this.getMapListings();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.search-page {
  background-color: $light-grey;
  overflow: auto;
  width: 100%;
  height: 100%;

  @media #{$tablet-landscape-and-up} {
    display: flex;
  }
}
.search-page-content {
  max-height: calc(100vh - 50px);
  overflow: auto;
  @media #{$tablet-landscape-and-up} {
    width: 66.66667%;
  }
}

.search-page-map {
  @media #{$tablet-landscape-and-up} {
    width: 33.33333%;
  }
}

// .hide-view {
//   display: none;
//   @media #{$tablet-landscape-and-up} {
//     display: block;
//   }
// }
</style>
